<template>
    <footer>
        <div class="row mx-auto" id="footer">
            <div class="col-12 col-lg-6 middle-footer ps-5">
                <img id="footerlogo" class="ms-0 ms-sm-5 mx-sm-auto" :src="logo" alt="Logo">
            </div>
            <div class="col-12 col-lg-6 left-footer mt-5 mt-md-0">
                <div class="footer-w-50 mx-auto">
                    <span class="contact-us-title fs-6">Nous contacter</span>
                    <div class="contact-info mt-sm-0 mt-md-3">
                        <span><img :src="home" alt="adresse">11 bis rue Félix Poyez 77000 MELUN</span>
                    </div>
                    <div class="contact-info">
                        <span><img :src="phone" alt="telephone">Tél. : 06 19 92 22 43</span>
                    </div>
                    <div class="contact-info">
                        <span><img :src="letter" alt="email">l.lahaulle@agiliwork.com</span>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import home from '@/assets/footer/home.svg'
import phone from '@/assets/footer/phone.svg'
import letter from '@/assets/footer/letter.svg'
import dark from '@/assets/footer/phone-dark.svg'
import facebook from '@/assets/footer/facebook.svg'
import instagram from '@/assets/footer/instagram.svg'
import linkedin from '@/assets/footer/linkedin.svg'
import logo from '@/assets/logo.png'
export default {
    name: 'FooterNav',
    data() {
        return {
            home: home,
            phone: phone,
            letter: letter,
            dark: dark,
            facebook: facebook,
            instagram: instagram,
            linkedin: linkedin,
            logo,
        }
    }
}
</script>

<style>

</style>
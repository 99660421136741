import axios from 'axios';
class OfficeService {
    getOffices(query = []) {
        query['team'] = query['team'] || 2 /*process.env.VUE_APP_TEAM*/;
        return axios.get('/api/offices/dispo?' + new URLSearchParams(query).toString());
    }

    getOfficeBySlug(slug) {
        return axios.get('/api/offices/' + slug);
    }
}

export default new OfficeService();
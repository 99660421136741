<template>
  <section>
    <ErrorComponent :error-number="404" :error-title="'Page non trouvée'" :error-description="'La page que vous tenez d’afficher n’existe pas ou une autre erreur s’est produite.<br> Vous pouvez revenir à la <b>page précédente</b> ou aller à la <b>page d’accueil.</b>'"  />
  </section>
</template>

<script>

import ErrorComponent from "@/components/ErrorComponent";
export default{
  name: 'ErrorView',
  components: {ErrorComponent},
  component: {
    ErrorComponent
  }
}
</script>
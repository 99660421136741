<template>
   
    <div class="d-flex my-5 flex-lg-nowrap flex-wrap">
            <div class="d-flex mb-2">
                <div class="img-domiciliation">
                    <img :src="balance" alt="balance">
                
                </div>
                <div>
                    <p class="text-balance py-0 my-0">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus
                        venenatis
                        elit ut aliquam,
                        purus sit amet luctus venenatis. purus sit
                    </p>
                </div>
            </div>
            <div class="carrousel-domiciliation col-12">
                <div class="wrapper">
                    <First-section-child v-for="( card, index) in data" :datainfo="card" :key="index" />
                </div>
            </div>
    </div>
  
</template>

<script>
import FirstSectionChild  from './FirstSectionChild.vue'
import balance from '@/assets/domiciliation/balance.svg'


export default {
    name: "FirstSection",
    components: {
        FirstSectionChild ,
    },
    data: function () {
       
        const info = [
                {
                    title: "Boîte aux lettres autonome",
                    content: "Acceder à votre boîte aux lettres h24 - 7j/7"
                }, 
                {
                    title: "Tarifs transparents",
                    content: "Des tarifs fixes, sans frais cachés, caution ou dépôt de garantie à partir de 6,49€/mois."
                },
                {
                    title: "Services sur mesure", 
                    content: "Location de salle et de bureaux, standard téléphonique, banque en ligne, …"
                },
                {
                    title: "Adresse professionnelle",
                    content: "Une sélection d’adresses et de centres d’affaires dans toute la France."
                },
                {
                    title: "Gestion du courrier",
                    content: "Mise à disposition, réexpédition ou numérisation choisissez votre option dans toutes nos offres."
                }
            ]
          
        return {
        balance: balance,
        data: info
        
        }
    },
   
   
    
}
</script>

<style lang="scss">
   // margin: 0 !important;
    .img-domiciliation {
      width: 278px;
      height: 128px;
      background: linear-gradient(90deg, #656ae5 -19.06%, #de84fd 100%);
      display: flex;
      justify-content: end;
      align-items: center;
      padding: 29px;
    }

    .text-balance {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 19px;
      color: #3d3d3c;
      width: 167px;
      height: 140px;
      padding: 2px 8px;
      margin-left: 12px;
    }

    .carrousel-domiciliation {
      //width: 783px;
      width: 100vw;
      height: max-content;
      overflow-y: hidden;
      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      .wrapper {
        width: max-content;
        display: flex;
        flex-direction: row;
      }

      .border-domiciliation {
        border-image: linear-gradient(180deg, #656ae5, #de84fd) 1 100%;
        border-left-width: 6px;
        border-right-width: 0;
        border-style: solid;
        padding: 10px;
        height: 132px;
        max-height: 162px;
        max-width: 259px;

        h3 {
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 21px;
          color: #21215c;
        }

        p {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;
          color: #3d3d3c;
        }
      }
    }

</style>
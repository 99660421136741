<template>
    <div class="item col-2 p-0" data-bs-toggle="modal" :data-bs-target="'see-photo' + photo.id">
        <img width="100%" :src="'https://www.centreo-beta.fr/images/gallery/'+ photo.image" alt="">
    </div>
    
</template>

<script>
export default {
    name: "GaleryOffice",
    props: {
        photo: Object,
    },
}
</script>

<style>

</style>

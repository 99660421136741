<template>

	<section class="first-section" id="offices">
		<div class="container">
			<div class="hide-mobile">
				<div class="search-wrap">
					<div class="search-fields office-fields">

						<div class="form-search select-item d-flex flex-row align-self-baseline align-items-center input"
							style="position:relative;">
							<img :src="DesktopTower" alt="Date"
								style="width:23px;position: absolute;left: 18px;top:14px;z-index: 5;">
							<select v-model="query.type" name="types" class="nice-select wide form-control input"
								id="bureau">
								<option disabled selected>Type de bureau</option>
								<option value="permanent">Permanent</option>
								<option value="shared">Partagé</option>
								<option value="temporary">Occasionnel</option>
							</select>
						</div>
						<div class="hr"></div>
						<div class="form-search input-item">
							<div class="input-icon">
								<img :src="SearchCalendar" alt="Heure">
							</div>
							<input v-model="query.dateDebut" type="text" class="form-control input datepicker ml-3"
								style="cursor:pointer;" id="dateDebut" name="dateDebut" placeholder="Dates de début">
						</div>
						<div class="hr"></div>
						<div class="form-search input-item">
							<div class="input-icon">
								<img :src="SearchCalendar" alt="Heure">
							</div>
							<input v-model="query.dateFin" type="text" class="form-control input datepicker ml-3"
								style="cursor:pointer;" id="dateFin" name="dateFin" placeholder="Dates de fin">
						</div>
						<div class="hr"></div>
						<div class="form-search input-item">
							<div class="input-icon">
								<img :src="SearchMoney" alt="Euro">
							</div>
							<input v-model="query.budget" type="text" name="permanentTariff" id="budget"
								class="form-control pl-2" placeholder="Budget">
						</div>
						<div class="hr"></div>
						<div class="form-search input-item">
							<div class="input-icon">
								<img :src="SearchMove" alt="Dimension">
							</div>
							<input v-model="query.surface" type="text" name="surface" id="area"
								class="form-control pl-2 w-100" placeholder="Surface">
						</div>
						<div class="hr"></div>
						<div class="form-search input-item">
							<div class="input-icon">
								<img :src="FiUsersBlue" alt="Dimension">
							</div>
							<input v-model="query.person" type="text" name="nbPeople" id="person"
								class="form-control pl-2 w-100" placeholder="Personnes">
						</div>

					</div>
					<div class="search-actions">
						<button class="btn search-btn" @click="getOffices()">
							<img :src="SearchBtn" alt="">
						</button>
					</div>
				</div>
			</div>

			<div class="hide-desktop">
				<form class="search-wrap" method="get" action="#">
					<div class="search-fields">
						<div class="input-group global">
							<div class="datetime-group form-search input-item">
								<div class="input-icon">
									<img :src="SearchCalendar" alt="Date">
								</div>
								<input type="text" class="form-control datepicker pl-2" placeholder="Date de début">
							</div>

							<div class="form-search filter-group input-item">
								<button class="btn toggle-filters-mobile" type="button">
									<img :src="FiltersIcon" alt="">
								</button>
							</div>
						</div>
						<div class="search-dropdowns">
							<div class="inputs">
								<div class="form-search input-item">
									<div class="input-icon">
										<img :src="SearchCalendar" alt="Heure">
									</div>
									<input type="text" class="form-control input datepicker ml-3"
										style="cursor:pointer;" id="dateFin" name="dateFin" placeholder="Dates de fin">
								</div>
								<div class="form-search input-item">
									<div class="input-icon">
										<img :src="SearchMoney" alt="Euro">
									</div>
									<input type="text" name="budget" id="budget1" class="form-control pl-2"
										placeholder="Budget">
								</div>
								<div class="form-search input-item">
									<div class="input-icon">
										<img :src="FiUsersBlue" alt="Dimension">
									</div>
									<input type="text" name="person" id="person1" class="form-control pl-2 w-100"
										placeholder="Personnes">
								</div>
								<div class="form-search input-item">
									<div class="input-icon">
										<img :src="SearchMove" alt="Dimension">
									</div>
									<input type="text" name="area" id="area1" class="form-control pl-2 w-100"
										placeholder="Surface">
								</div>
								<div class="form-search input-item">
									<select class="form-control nice-select wide" id="bureau1">
										<option disabled selected>Type de bureau</option>
										<option>Permanent</option>
										<option>Partagé</option>
										<option>Occasionnel</option>
									</select>
								</div>
							</div>
							<div class="search-actions">
								<button class="btn search-btn">
									Rechercher
								</button>
							</div>
						</div>
					</div>
					<div class="search-actions">
						<button type="button" class="btn search-btn" @click="this.getOffices">
							<img :src="SearchBtn" alt="">
						</button>
					</div>
				</form>
			</div>

			<div v-if="offices.length" class="row">
				<div v-for="office in offices" :key="office.id" class="col-12 col-md-6 mb-4">
					<OfficeComponent :office="office" />
				</div>
			</div>
			<div v-else class="row">
				<div v-for="index in 6" :key="index" class="col-12 col-md-6 mb-4">
					<OfficeLoader />
				</div>
			</div>

		</div>
	</section>

</template>

<script>

import DesktopTower from '@/assets/offices/DesktopTower.png'
import FiltersIcon from '@/assets/offices/filters-icon.svg'
import SearchBtn from '@/assets/offices/search-btn.svg'
import SearchCalendar from '@/assets/offices/search-calendar.svg'
import SearchMoney from '@/assets/offices/search-money.svg'
import SearchMove from '@/assets/offices/search-move.svg'
import FiUsersBlue from '@/assets/offices/fi_users_blue.png'
import OfficeComponent from '@/components/OfficeComponent.vue'
import OfficeService from '@/services/office.js'
import OfficeLoader from '@/components/loaders/OfficeLoader.vue'

export default {
	name: 'OfficesView',
	components: {
		OfficeComponent,
		OfficeLoader
	},
	data() {
		return {
			DesktopTower,
			FiltersIcon,
			SearchBtn,
			SearchCalendar,
			SearchMoney,
			SearchMove,
			FiUsersBlue,
			offices: [],
			query: {
				type: '',
				dateDebut: '',
				dateFin: '',
				budget: '',
				person: '',
				surface: '',
			}
		}
	},
	mounted() {
		document.title = 'Déclic Center | Bureaux';
		this.getOffices();
	},
	methods: {
		getOffices() {
			this.offices = [];
			OfficeService.getOffices(this.query)
				.then(response => {
					setTimeout(() => {
						this.offices = response.data['hydra:member'];
					}, 1000);
				})
				.catch(e => {
					console.log(e);
				});
		},
	},
};
</script>

import axios from 'axios';
class DocumentService{

    document(data ) {
        return axios
            .post('/api/documents', 
               data
            )
            .then((response) => {
                console.log(response)
            })
            .catch((e) => {
                this.errors.push(e)
            })
          
    }
}
export default new DocumentService();
<template>
    <div class="bloc-info">
      <div class="error">
        <h1>{{ errorNumber }}</h1>
      </div>
      <div class="title">
        <h2>{{ errorTitle }}</h2>
      </div>
      <div class="content">
        <p v-html="errorDescription"></p>
      </div>
      <div class="bloc-btn">
        <div class="btn-back">
          <button type="button" @click="this.goBack">Retour</button>
        </div>
        <div class="btn-home">
          <router-link to="/">Page d'accueil</router-link>
        </div>
      </div>
    </div>
</template>

<script>
    import router from "@/router";

    export default {
        name: 'ErrorComponent',
      props: {
          errorNumber: Number,
          errorTitle: String,
          errorDescription: String
      },
      methods:{
          goBack(){
            router.go(-1)
          }
      }
    };
</script>

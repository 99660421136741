<template>
  <section class="first-section" id="home">
    <div class="container-fluid">
      <div class="row px-0 mx-0">
        <div class="col-12 col-xl-5">
          <h1>Bienvenue,</h1>
          <h2>
            dans notre Centre d’affaires à Melun
          </h2>
        </div>
      </div>
      <div class="row px-0 mx-0 first-row">
        <form id="contactForm" action="" method="POST" class="form">
          <h3>Contactez-nous</h3>
          <div class="row px-0 mx-0 form-group">
            <div id="auth-error" class="col-12 alert text-center w-100 fade hide" :class="{ 'show': status.message }"
              role="alert">
              <span class="error-text">{{ status.message }}</span>
            </div>
            <div class="col-6" :class="{ error: v$.first.$errors.length }">
              <input v-model="state.first" @blur="v$.first.$touch" type="text" name="first" id="first"
                placeholder="Prénom" class="form-control">
              <div class="input-errors" v-for="error of v$.first.$errors" :key="error.$uid">
                <span class="error-text">{{ error.$message }}</span>
              </div>
            </div>
            <div class="col-6" :class="{ error: v$.last.$errors.length }">
              <input v-model="state.last" @blur="v$.last.$touch" type="text" name="last" id="last" placeholder="Nom"
                class="form-control">
              <div class="input-errors" v-for="error of v$.last.$errors" :key="error.$uid">
                <span class="error-text">{{ error.$message }}</span>
              </div>
            </div>

            <div class="col-12 my-2" :class="{ error: v$.email.$errors.length }">
              <input v-model="state.email" @blur="v$.email.$touch" type="email" name="email" id="email"
                placeholder="Adresse mail" class="form-control">
              <div class="input-errors" v-for="error of v$.email.$errors" :key="error.$uid">
                <span class="error-text">{{ error.$message }}</span>
              </div>
            </div>

            <div class="col-12" :class="{ error: v$.motif.$errors.length }">
              <select v-model="state.motif" @blur="v$.motif.$touch" name="types"
                class="nice-select wide form-control input">
                <option disabled value="">Motif du message</option>
                <option value="domiciliation">Domiciliation</option>
                <option value="réservation">Réservation</option>
                <option value="demande d'information">Demande d'information</option>
                <option value="réclamation">Réclamation</option>
                <option value="autre">Autre</option>
              </select>
              <div class="input-errors" v-for="error of v$.motif.$errors" :key="error.$uid">
                <span class="error-text">{{ error.$message }}</span>
              </div>
            </div>
            <div class="col-12 my-2" :class="{ error: v$.message.$errors.length }">
              <textarea v-model="state.message" @blur="v$.message.$touch" name="message" id="message" cols="30"
                rows="10" placeholder="Message" class="form-control"></textarea>
              <div class="input-errors" v-for="error of v$.message.$errors" :key="error.$uid">
                <span class="error-text">{{ error.$message }}</span>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <div class="form-check">
                  <label id="agree-register-label" class="form-check-label" :class="{ error: v$.agree.$errors.length }">
                    <input v-model="v$.agree.$model" @change="v$.agree.$touch" type="checkbox" class="form-check-input"
                      name="agree-register" id="agree-register" value="checkedValue" required>
                    J'ai lu et j'accepte <strong><a href="#" data-bs-toggle="modal" data-bs-target="#agreeModal">les
                        conditions
                        générales</a></strong> de Déclic center
                  </label>
                </div>
                <div class="input-errors" v-for="error of v$.agree.$errors" :key="error.$uid">
                  <span class="error-text">{{ error.$message }}</span>
                </div>
              </div>
            </div>
            <div class="col-12 d-flex justify-content-center">
              <button id="contactSubmit" type="button" class="btn btn-submit" @click="submit">Envoyer</button>
            </div>
          </div>
          <TermsModal />
        </form>
        <div class="info">
          <div id="services" class="services d-flex justify-content-around mx-auto">
            <div class="service-item">
              <img class="item-image dom blue" :src="Dom" alt="Domiciliation">
              <div class="item-content blue">
                <h4 class="text-center">Domiciliation</h4>
                <p>Domiciliez votre siège social ou votre antenne commerciale à Melun et bénéficiez d’une adresse proche
                  de Paris.
                  Cette domiciliation offre une image et un accueil professionnel à tous vos interlocuteurs.</p>
                <div class="d-flex justify-content-center">
                  <a href="#services-section" class="btn text-white">Voir plus</a>
                </div>
              </div>
            </div>
            <div class="service-item">
              <img class="item-image purple" :src="bureau" alt="Bureaux">
              <div class="item-content purple">
                <h4 class="text-center">Bureaux</h4>
                <p>Découvrez nos différents bureaux, tout équipés et adaptés à vos besoins, à la location en formule
                  récurrente ou temporaire.</p>
                <div class="d-flex justify-content-center">
                  <a href="#office-section" class="btn text-white">Voir plus</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="discover" class="landing-scroll mx-auto">
      <span class="chevron bottom" @click="chevronScroll"></span>
    </div>

    <div id="services-section" class="services-section">
      <div class="container-fluid">
        <div class="service dombg">
          <div class="service-item">
            <h1 class="font-weight-light">Nos offres de domiciliation</h1>
            <p class="mt-3">Leaders de la domiciliation sur Melun (ville-préfecture à 30 minutes de Paris) depuis 2008,
              nous mettons notre savoir-faire au profit de votre entreprise. N’hésitez pas à nous contacter pour avoir
              plus d’informations ou souscrire à nos offres.</p>
            <div class="container-offer-values">
              <div class="offer-values">
                <div class="offer-values-img">
                  <img :src="mobility" alt="règle">
                </div>
                <h6 class="offer-values-title text-center mt-1">
                  Mobilité
                </h6>
                <p class="offer-values-text">
                  À proximité immédiate de la gare de Melun
                  et 30 minutes de Paris.</p>
              </div>

              <div class="offer-values">
                <div class="offer-values-img">
                  <img :src="adaptability" alt="palette de couleur">
                </div>
                <h6 class="offer-values-title text-center mt-1">
                  Adaptabilité
                </h6>
                <p class="offer-values-text">
                  De nombreuses options ajustables à vos besoin.
                </p>
              </div>

              <div class="offer-values">
                <div class="offer-values-img">
                  <img :src="confidentiality" alt="double carré">
                </div>
                <h6 class="offer-values-title text-center mt-1">
                  Confidentialité
                </h6>
                <p class="offer-values-text">
                  Service courrier sécurisé et confidentiel.
                </p>
              </div>

              <div class="offer-values no-after">
                <div class="offer-values-img">
                  <img :src="rigeur" alt="oeil">
                </div>
                <h6 class="offer-values-title text-center mt-1">
                  Rigueur
                </h6>
                <p class="offer-values-text">
                  Maîtrise des processus métiers et respect de la législation (agrément de la préfecture).
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section id="domiciliation">
        <div class="scope">
          <section id="offer">
            <div class="infos">
              <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="pills-standard-quarterly" role="tabpanel"
                  aria-labelledby="pills-standard-quarterly-tab">
                  <div class="contain-card-offer">
                    <div class="contain-form">
                      <div class="card-offer card-offer-purpledark">
                        <div class="content-title">
                          <h3>DOMICILIATION</h3>
                        </div>
                        <div class="domiciliation-info">
                          <p class="title-offer">Domiciliation Trimestrielle</p>

                          <p class="price">À partir de <span class="info-price info-pricepurpledark">39,00 € HT</span>
                            par mois</p>
                          <p class="info-duration text-center">Engagement de 3 mois minimum</p>

                          <hr>
                          <p class="title-offer">Domiciliation Trimestrielle avec Réexpédition</p>
                          <p class="price">À partir de <span class="info-price info-pricepurpledark">49,00 € HT</span>
                            par mois</p>
                          <p class="info-duration text-center">Engagement de 3 mois minimum</p>
                        </div>
                        <div class="container-option-scan position-relative container-option-scan-dom">
                          <div class="card">
                            <h5 class="mb-0">
                              <div class="btn-collapse">
                                Option scan pro <span class="scan-pro">
                                  <span>15,00 €</span>
                                  <span class="scanpro">&nbsp;HT par mois</span>
                                </span>
                              </div>
                            </h5>
                          </div>
                          <div id="collapseDimiciliation" class="collapse collapse-scan" aria-labelledby="headingOne"
                            data-parent="#accordion">
                            <div class="card-body">
                              L’option scan pro permet de ...........<br>
                              sit amet, consectetur adipiscing elit ut aliquam, purus sit amet
                              luctus venenatis elit ut aliquam, purus sit amet
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="contain-form">
                      <div class="card-offer card-offer-pink">
                        <div class="content-title orange">
                          <h3>PERMANENCE<br>TÉLÉPHONIQUE</h3>
                        </div>

                        <div class="telephone-permanence">
                          <p class="basic-offer text-center">
                            À partir de<span class="basic-call">&nbsp;20 appels*&nbsp;</span>pour
                          </p>
                          <p class="basic-offer"><span class="basic-call-price">30,00 &euro; HT</span> <span
                              class="basic-month">&nbsp;par mois</span></p>
                          <span class="title-slice">Tarifs dégressifs</span>
                          <p class="content-slice">
                            <span>
                              De <span class="fw-bold fs-6">20</span> à <span class="fw-bold fs-6">30</span> appels
                              pour
                            </span>
                            <span class="fs-6 fw-bold">40 € HT</span>
                          </p>
                          <p class="content-slice">
                            <span>
                              De <span class="fw-bold fs-6">30</span> à <span class="fw-bold fs-6">40</span> appels
                              pour
                            </span>
                            <span class="fs-6 fw-bold">50 € HT</span>
                          </p>
                          <p class="content-slice">
                            <span>
                              De <span class="fw-bold fs-6">40</span> à <span class="fw-bold fs-6">50</span> appels
                              pour
                            </span>
                            <span class="fs-6 fw-bold">60 € HT</span>
                          </p>
                        </div>
                        <p class="info-hotline">*Lorsque vous dépassez le nombre d’appels, vous serez automatiquement
                          facturé à la tranche d’appels supérieure.</p>
                      </div>
                    </div>

                    <div class="contain-form">
                      <div class="card-offer card-offer-purple">
                        <div class="content-title yellow">
                          <span class="pingle place-discount"></span>
                          <span class="pingle shadow-triangle-top-left "></span>
                          <span class="pingle discount-left">
                            <span class="pingle save-monthly top">
                              Économisez
                            </span>
                            <span class="pingle amount">
                              - 9
                            </span>
                            <span class="pingle euro">
                              €
                              <span class="pingle no-tax">HT</span>
                            </span>
                            <span class="pingle save-monthly bottom">PAR MOIS</span></span>
                          <span class="pingle shadow-triangle-bottom-left "></span>
                          <h3>DOMICILIATION</h3>
                          <span>ET PERMANENCE TÉLÉPHONIQUE</span>
                          <span class="promo">Économisez - 9 € HT par mois</span>
                        </div>
                        <div class="domiciliation-info">
                          <p class="title-offer">Domiciliation Trimestrielle avec 20 appels *</p>

                          <p class="price"><span class="info-price info-pricepurpledark">60,00 € HT</span>
                            par mois</p>
                          <p class="info-duration text-center">Engagement de 3 mois minimum</p>

                          <hr>
                          <p class="title-offer">Domiciliation Trimestrielle avec 20 appels et réexpédition *</p>
                          <p class="price">À partir de <span class="info-price info-pricepurpledark">70,00 € HT</span>
                            par mois</p>
                          <p class="info-duration text-center">Engagement de 3 mois minimum</p>
                        </div>
                        <div class="container-option-scan position-relative container-option-scan-perm">
                          <div class="card">
                            <h5 class="mb-0">
                              <div class="btn-collapse yellow">Option scan pro <span class="scan-pro"><span>15,00
                                    €</span><span class="scanpro">&nbsp;HT par mois</span></span>
                              </div>
                            </h5>
                          </div>
                          <div id="collapseScanProTri" class="collapse collapse-scan" aria-labelledby="headingOne"
                            data-parent="#accordion">
                            <div class="card-body">
                              L’option scan pro permet de ...........<br>
                              sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis elit
                              ut aliquam, purus sit amet
                            </div>
                          </div>


                        </div>

                      </div>
                    </div>
                  </div>
                  <div id="souscrire" class="contain-btn text-center">
                    <a href="#contactForm" class="btn btn-submit" @click="setMotif('domiciliation')">
                      <span class="text-white ps-0 pb-0 mb-0">Nous contacter</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
      <div id="office-section" class="container-fluid">
        <div class="service officebg">
          <div class="service-item">
            <h1 class="font-weight-light">Nos bureaux</h1>
            <p class="mt-3">De 11m<sup>2</sup> à 15m<sup>2</sup> : bureaux permanents, temporaires ou à temps partagé.
              à l’heure, à la journée ou au mois, venez profiter d’un cadre de travail adapté et d’un écosystème
              d’entreprises enrichissant. N’hésitez pas à nous contacter pour plus d’informations.
            </p>
            <div class="container-offer-values">
              <div class="offer-values">
                <div class="offer-values-img">
                  <img :src="mobility" alt="règle">
                </div>
                <h6 class="offer-values-title text-center mt-1">
                  Mobilité
                </h6>
                <p class="offer-values-text">
                  À proximité immédiate de la gare de Melun et 30 minutes de Paris.
                </p>
              </div>

              <div class="offer-values">
                <div class="offer-values-img">
                  <img :src="flexibility" alt="palette de couleur">
                </div>
                <h6 class="offer-values-title text-center mt-1">
                  Flexibilité
                </h6>
                <p class="offer-values-text">
                  Plusieurs formules pour disposer d’un bureau à un tarif adapté à vos besoins.
                </p>
              </div>

              <div class="offer-values">
                <div class="offer-values-img">
                  <img :src="accessibility" alt="double carré">
                </div>
                <h6 class="offer-values-title text-center mt-1">
                  Accessibilité
                </h6>
                <p class="offer-values-text">
                  Accueil locataires : accès 24h/24, 7j/7
                </p>
              </div>
              <div class="offer-values no-after">
                <div class="offer-values-img">
                  <img :src="simplicity" alt="oeil">
                </div>
                <h6 class="offer-values-title text-center mt-1">
                  Simplicité
                </h6>
                <p class="offer-values-text">
                  Un bureau tout équipé prêt à l’emploi (téléphonie, internet, mobilier, etc).
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import { reactive } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, email, helpers, requiredIf } from '@vuelidate/validators'
import TermsModal from '@/components/TermsOfUse.vue'
import axios from 'axios';
export default {
  name: 'HomeView',
  metaInfo: {
    title: 'Déclic Center',
    meta: [
      {
        name: 'keywords',
        content: 'Domiciliation Melun/77, domiciliation commerciale Melun/77, domiciliation entreprise Melun/77, domiciliation juridique Melun/77, permanence téléphonique Melun/77, location de bureau Melun/77'
      },
      {
        name: 'description',
        content: 'Notre centre d’affaires est situé à Melun en Seine-et-Marne. Nous proposons la domiciliation, la permanence téléphonique et la location de bureaux pour les entreprises. Venez découvrir nos offres !'
      }
    ]
  },
  setup() {
    const state = reactive({
      last: '',
      first: '',
      email: '',
      motif: '',
      message: '',
      agree: null,
    })

    const rules = {
      last: {
        required: helpers.withMessage('Veuillez renseigner votre nom', required),
      },
      first: {
        required: helpers.withMessage('Veuillez renseigner votre prénom', required),
      },
      email: {
        required: helpers.withMessage('Veuillez renseigner votre email', required),
        email: helpers.withMessage('Veuillez renseigner un email valide', email),
      },
      motif: {
        required: helpers.withMessage('Veuillez renseigner le motif de votre demande', requiredIf(state => state.motif !== '')),
      },
      message: {
        required: helpers.withMessage('Veuillez renseigner votre message', required),
      },
      agree: {
        required: helpers.withMessage('Veuillez accepter les conditions d\'utilisation', required),
      },
    }
    const v$ = useVuelidate(rules, state)
    return {
      state,
      v$,
    }
  },
  components: {
    TermsModal,
  },
  data() {
    return {
      palette: require('../assets/home/palette.svg'),
      bureau: require('../assets/home/bureaux.svg'),
      Dom: require('../assets/home/domiciliation.svg'),
      mobility: require('../assets/icons/Marker.svg'),
      adaptability: require('../assets/icons/adaptability.svg'),
      confidentiality: require('../assets/icons/lock.svg'),
      rigeur: require('../assets/icons/doc.svg'),
      flexibility: require('../assets/icons/puzzle.svg'),
      accessibility: require('../assets/icons/accessibility.svg'),
      simplicity: require('../assets/icons/simplicity.svg'),
      status: {
        loading: false,
        success: false,
        error: false,
        message: ''
      },
    };
  },
  methods: {
    chevronScroll() {
      const el = document.getElementById('services-section');
      el.scrollIntoView({ behavior: 'smooth' });
    },
    setMotif(motif) {
      this.state.motif = motif;
    },
    async submit() {
      console.table(this.state);
      const isValid = await this.v$.$validate();
      if (!isValid) {
        return;
      }

      this.status.loading = true;
      axios.post('/centreo/declic/contact', JSON.stringify(this.state))
        .then(() => {
          this.status.loading = false;
          this.status.success = true;
          this.status.message = 'Votre message a bien été envoyé';
          this.state.last = '';
          this.state.first = '';
          this.state.email = '';
          this.state.motif = '';
          this.state.message = '';
          this.state.agree = null;
          this.v$.$reset();
          this.$swal({
            title: 'Demande envoyée!',
            text: this.status.message,
            icon: 'success',
          })
        })
        .catch(() => {
          this.status.loading = false;
          this.status.error = true;
          this.status.message = false;
          this.status.message = 'Une erreur est survenue, veuillez réessayer ultérieurement';
          this.$swal({
            title: 'Erreur!',
            text: this.status.message,
            icon: 'error',
          })
        });
    },
  },
};
</script>

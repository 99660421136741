<template>
  <HeaderNav />
  <router-view />
  <FooterNav />
</template>
<script>
import './styles/app.scss'
import HeaderNav from '@/components/Header.vue'
import FooterNav from '@/components/Footer.vue'

export default {
  name: 'App',
  components: {
    HeaderNav,
    FooterNav,
  },
  data() {
    return {
      clientId: String
    }
  },
  mounted() {
    if (process.env.NODE_ENV === 'development') {
      this.client_Id = "63906f600b9707bd9e7e08ea"

    } else {
      this.client_Id = "63a1d0aaee354427b45ccce2"
    }
    window.axeptioSettings = {
      clientId: this.client_Id,
    };
    (function (d, s) {
      var t = d.getElementsByTagName(s)[0], e = d.createElement(s);
      e.async = true; e.src = "//static.axept.io/sdk.js";
      t.parentNode.insertBefore(e, t);
    })(document, "script");
  },
}

</script>

<style lang="scss">
#app {
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin: auto;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #b984fd;
    }
  }
}
</style>

import {
  createRouter,
  createWebHistory
} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import OfficesView from '@/views/OfficesView.vue'
import DomiciliationView from '@/views/DomiciliationView.vue'
import DomiciliationFormView from '@/views/DomiciliationFormView.vue'
import CenterView from '@/views/CenterView.vue'
import GalleryView from '@/views/GalleryView.vue'
import AuthView from '@/views/AuthView.vue'
import ErrorView from "@/views/ErrorView.vue";
import ForgotPasswordView from "@/views/ForgotPasswordView.vue";
import ProfilView from "@/views/ProfilView.vue";
import OfficeDetailView from '@/views/OfficeDetailView.vue'


const routes = [{
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/auth',
    name: 'auth',
    component: AuthView
  },
  {
    path: '/bureaux',
    name: 'offices',
    component: OfficesView,
  },
  {
    path: '/bureau/:slug',
    name: 'office',
    component: OfficeDetailView
  },
  {
    path: '/domiciliation',
    name: 'domiciliation',
    component: DomiciliationView,
  },
  {
    path: '/domiciliation/reservation',
    name: 'resDom',
    component: DomiciliationFormView,
  },
  {
    path: '/notre-centre',
    name: 'center',
    component: CenterView
  },
  {
    path: '/galerie',
    name: 'gallery',
    component: GalleryView
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPasswordView
  },
  {
    path: '/profil',
    name: 'profil',
    component: ProfilView
  },

  //Route error toujours en dernier pour verifier avant si existe les autres routes.
  {
    path: '/:pathMatch(.*)*',
    name: 'erreur',
    component: ErrorView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/', '/auth', '/bureaux', '/bureau', '/domiciliation', '/notre-centre', '/galerie', '/erreur', '/forgot-password'];
  const authRequired = !publicPages.includes(to.path);
  const vuex = localStorage.getItem('vuex');
  if (authRequired && vuex !== null) {
    if (!JSON.parse(vuex).auth.status.loggedIn)
      next('/auth');
  }
  next();
});


export default router

<template>
  <!-- Modal gallery -->
    <div class="modal fade" :id="'see-photo' + { key }" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog {{ (size is defined)? 'modal-lg modal-dialog-centered' : '' }}" role="document">
            <div class="modal-content">
                <div class="modal-body d-flex justify-content-end">
                    <div class="card-class">
                        <button type="button" class="close" data-dismiss="modal">
                            <span aria-hidden="true"><img class="d-block icon-close"
                                    src="" alt=""></span>
                        </button>
                        <div class="text-center">
                            <h2 class="title-card">{{ photo.title}}</h2>
                            <p class="line-card mx-auto"> </p>
                            <p class="date-card">12/03/2020</p>
                            <p class="text-card mx-auto">Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam,
                                purus sit amet luctus venenatis elit ut aliquam,
                                purus sit amet luctus venenatis. purus sit amet luctus venenatis elit ut aliquam, purus sit
                                amet luctus venenatis.</p>

                            <a href="#" class="btn btn-card ">Voir la salle</a>
                        </div>
                    </div>

                 
                    <img :src="'https://www.centreo-beta.fr/images/gallery/'+ photo.image" alt="image">
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "GalleryModal",
    props: {
        photo: Object,
    },
}
</script>

<style>

</style>
<template>

	Notre centre



	<section id="center" class="first-section">
		<img :src="plante" alt="plante" class="img-cover">
		<div class="first-section-center">
			<div class="history d-flex justify-content-md-center justify-content-lg-end">
				<div class="col-md-12 col-lg-8 p-md-2 pr-lg-5">
					<h2 class="title-center">Notre histoire</h2>
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus
						venenatis
						elit
						ut aliquam, purus sit amet luctus venenatis. purus sit amet luctus venenatis elit ut aliquam,
						purus
						sit
						amet luctus venenatis. </p>
				</div>
			</div>
			<div class="values">
				<h2 class="title-center">Nos valeurs</h2>
				<div class="container-offer-values">

					<div class="offer-values">
						<div class="offer-values-img">
							<img :src="value1" alt="règle">
						</div>
						<div class="title">
							<h3 class="offer-values-title">
								Offres personnalisées
							</h3>
							<p class="offer-values-text">
								Lorem ipsum dolor sit amet, consectetur
								adipiscing elit ut aliquam, purus sit amet
								luctus venenatis elit ut aliquam
							</p>
						</div>
					</div>

					<div class="offer-values">
						<div class="offer-values-img">
							<img :src="palette" alt="palette de couleur">
						</div>
						<div class="title">
							<h3 class="offer-values-title">
								Offres personnalisées
							</h3>
							<p class="offer-values-text">
								Lorem ipsum dolor sit amet, consectetur
								adipiscing elit ut aliquam, purus sit amet
								luctus venenatis elit ut aliquam
							</p>
						</div>
					</div>

					<div class="offer-values">
						<div class="offer-values-img">
							<img :src="imagessquare" alt="double carré">
						</div>
						<div class="title">
							<h3 class="offer-values-title">
								Offres personnalisées
							</h3>
							<p class="offer-values-text">
								Lorem ipsum dolor sit amet, consectetur
								adipiscing elit ut aliquam, purus sit amet
								luctus venenatis elit ut aliquam
							</p>
						</div>
					</div>

					<div class="offer-values">
						<div class="offer-values-img">
							<img :src="eye" alt="oeil">
						</div>
						<div class="title">
							<h3 class="offer-values-title">
								Offres personnalisées
							</h3>
							<p class="offer-values-text">
								Lorem ipsum dolor sit amet, consectetur
								adipiscing elit ut aliquam, purus sit amet
								luctus venenatis elit ut aliquam
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="">

			<div class="services">
				<div class="row justify-content-center">
					<h2 class="title-center col-xl-8 col-12">Nos services</h2>
				</div>

				<div id="infos-content">
					<div class="switch-work">
						<ul class="nav nav-pills my-4 justify-content-center" id="pills-tab" role="tablist">
							<li>
								<a class="button-switch active standard first-button" id="pills-domiciliation-tab"
									data-bs-toggle="pill" href="#pills-domiciliation" role="tab"
									aria-controls="pills-domiciliation" aria-selected="false">Domiciliation</a>
							</li>
							<li>
								<a class="button-switch standard second-button" id="pills-office-tab"
									data-bs-toggle="pill" href="#pills-office" role="tab"
									aria-controls="pills-office-tab" aria-selected="true">Location de bureaux</a>
							</li>
							<li>
								<a class="button-switch standard third-button" id="pills-room-tab" data-bs-toggle="pill"
									href="#pills-room" role="tab" aria-controls="pills-room-tab"
									aria-selected="true">Location de salles</a>
							</li>
						</ul>
					</div>
					<div class="infos">
						<div class="tab-content" id="pills-tabContent">
							<div class="tab-pane fade show active" id="pills-domiciliation" role="tabpanel"
								aria-labelledby="pills-domiciliation-tab">

								<First-section />

								<div class="container-domiciliation-offer container">
									<div class="item-domiciliation-offer">
										<h2>Domiciliation</h2>
										<div>
											<p>À partir de</p>
											<p class="d-flex justify-content-center"><span class="price">49</span> <span
													class="d-flex flex-column"><span class="euro">€</span><span
														class="taxe">HT</span></span></p>
											<p>par mois</p>
											<p class="engagement">Engagement de 3 mois minimum</p>
										</div>
										<div class="container-btn">
											<a href="#" class="btn btn-more">En savoir plus</a>
										</div>
									</div>

									<div class="item-domiciliation-offer second-item">
										<h2>permanence téléphonique</h2>
										<div>
											<p>À partir de</p>
											<p class="d-flex justify-content-center"><span class="price">49</span> <span
													class="d-flex flex-column"><span class="euro">€</span><span
														class="taxe">HT</span></span></p>
											<p>par mois</p>
											<p class="engagement">Engagement de 3 mois minimum</p>
										</div>
										<div class="container-btn">
											<a href="#" class="btn btn-more">En savoir plus</a>
										</div>
									</div>

									<div class="item-domiciliation-offer third-item">
										<h2>Domiciliation et permanence téléphonique</h2>
										<div>
											<p>À partir de</p>
											<p class="d-flex justify-content-center"><span class="price">69</span> <span
													class="d-flex flex-column"><span class="euro">€</span><span
														class="taxe">HT</span></span></p>
											<p>par mois</p>
											<p class="engagement">Engagement de 3 mois minimum</p>
										</div>
										<div class="container-btn">
											<a href="#" class="btn btn-more">En savoir plus</a>
										</div>
									</div>
								</div>
							</div>
							<div class="tab-pane bloc-bureaux fade" id="pills-office" role="tabpanel"
								aria-labelledby="pills-office-tab">
								<div class="container-office-detail container">
									<div class="container-office-type">
										<h2>Bureaux permanents</h2>
										<div class="container-offer-info">
											<div class="item-offer">
												<p>Offres personnalisées</p>
												<p>Offres personnalisées</p>
												<p>Offres personnalisées</p>
												<p>Offres personnalisées</p>
											</div>
											<div class="office-booking">
												<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam,
													purus
													sit amet luctus venenatis elit ut aliquam, purus sit amet luctus
													venenatis. purus sit </p>
												<a href="#" class="btn btn-booking">Réserver</a>
											</div>
										</div>
									</div>
									<div class="container-office-type second-item">
										<h2>Bureaux partagés</h2>
										<div class="container-offer-info">
											<div class="item-offer">
												<p>Offres personnalisées</p>
												<p>Offres personnalisées</p>
												<p>Offres personnalisées</p>
												<p>Offres personnalisées</p>
											</div>
											<div class="office-booking">
												<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam,
													purus
													sit amet luctus venenatis elit ut aliquam, purus sit amet luctus
													venenatis. purus sit </p>
												<a href="#" class="btn btn-booking">Réserver</a>
											</div>
										</div>
									</div>
									<div class="container-office-type third-item">
										<h2>Bureaux temporaires</h2>
										<div class="container-offer-info">
											<div class="item-offer">
												<p>Offres personnalisées</p>
												<p>Offres personnalisées</p>
												<p>Offres personnalisées</p>
												<p>Offres personnalisées</p>
											</div>
											<div class="office-booking">
												<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam,
													purus
													sit amet luctus venenatis elit ut aliquam, purus sit amet luctus
													venenatis. purus sit </p>
												<a href="#" class="btn btn-booking">Réserver</a>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="tab-pane bloc-bureaux fade" id="pills-room" role="tabpanel"
								aria-labelledby="pills-room-tab">
								<div class="container-office-detail container">
									<div class="container-office-type">
										<h2 class="text-uppercase">Salles pour les particuliers</h2>
										<div class="container-offer-info">
											<div class="item-offer">
												<p>Offres personnalisées</p>
												<p>Offres personnalisées</p>
												<p>Offres personnalisées</p>
												<p>Offres personnalisées</p>
											</div>
											<div class="office-booking">
												<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam,
													purus
													sit amet luctus venenatis elit ut aliquam, purus sit amet luctus
													venenatis. purus sit </p>
												<a href="#" class="btn btn-booking">Réserver</a>
											</div>
										</div>
									</div>
									<div class="container-office-type second-item">
										<h2 class="text-uppercase">Salles pour les professionnels</h2>
										<div class="container-offer-info">
											<div class="item-offer">
												<p>Offres personnalisées</p>
												<p>Offres personnalisées</p>
												<p>Offres personnalisées</p>
												<p>Offres personnalisées</p>
											</div>
											<div class="office-booking">
												<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam,
													purus
													sit amet luctus venenatis elit ut aliquam, purus sit amet luctus
													venenatis. purus sit </p>
												<a href="#" class="btn btn-booking">Réserver</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="staff container">
				<h2 class="title-center">Notre équipe</h2>

				<div class="container-staff">
					<Job  v-for="( job, index) in jobs" :datajob="job" :key="index"  />

				</div>
			</div>
		</div>

	</section>



</template>








<script>
import balance from '@/assets/domiciliation/balance.svg'
import FirstSection from "../components/domiciliation/FirstSection.vue"
import Job from "../components/Center.vue"
import CenterService from '@/services/center.js'
export default {
	name: 'enterView ',
	components: {
		FirstSection,
		Job

	},

	data: function () {
		
		return {
			balance: balance,
			palette: require('../assets/home/palette.svg'),
			imagessquare: require('../assets/home/double-square.svg'),
			eye: require('../assets/home/eye.svg'),
			value1: require('../assets/home/value1.svg'),
			plante: require('../assets/center/plante.png'),
			jobs: []
			
		}
		
	},
	mounted() {
		document.title = 'Déclic Center | Notre centre';
		
		this.getCenter()
		
	},
	methods: {
		getCenter() {
			
			CenterService.getCenter()
				.then(response => {
					this.jobs = response.data;
					
				})
				.catch(e => {
					console.log(e);
				});
				console.table(this.jobs)
		},
	},
}
</script>

<style>

</style>
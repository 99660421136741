<template>
    <div class="step-card">
        <div class="container-radio">
            <label>
                <input type="radio" name="type" value="Création de siège social" checked>
                <div class="img-radio">
                    <img :src="marker" width="100px" class="main-image">
                    <img :src="marker_hover" width="100px" class="hover-image">
                </div>
                <p>Création de siège social</p>
            </label>
            <label>
                <input type="radio" name="type" value="Changement de siège social">
                <div class="img-radio">
                    <img :src="changement" width="100px" class="main-image">
                    <img :src="changement_hover" width="100px" class="hover-image">
                </div>
                <p>Changement de siège social</p>
            </label>

            <label>
                <input type="radio" name="type" value="Créer une antenne commerciale">
                <div class="img-radio">
                    <img :src="antenne" width="100px" class="main-image">
                    <img :src="antenne_hover" width="100px" class="hover-image">
                </div>
                <p>Créer une antenne commerciale</p>
            </label>
        </div>
    </div>
</template>

<script>
export default {
    name: "CardStep",
    data: function () {
		
		return {
			
			marker: require('../assets/domiciliation/marker.svg'),
            marker_hover: require('../assets/domiciliation/marker-hover.svg'),
            changement_hover: require('../assets/domiciliation/changement-hover.svg'),
            changement: require('../assets/domiciliation/changement.svg'),
			antenne: require('../assets/domiciliation/antenne.svg'),
			antenne_hover: require('../assets/domiciliation/antenne-hover.svg'),
		
			
		}
		
	},


}
</script>

<style>

</style>
<template>


    <section class="wizard-section search-wizard-dom sweetAlert" id="form-section">

        <img :src="booking_dom_left" alt="" class="img-left">
        <img :src="booking_dom_right" alt="" class="img-right">
        <div class="container">
            <div class="row justify-content-center">
                <form action="" class="form-wizard" method="post" id="domiciliation-form" enctype="multipart/form-data">
                    <div class="step-app" id="wizard-form">
                        <ul class="step-steps step-steps-domiciliation">

                            <li class="etape" data-step-target="step1" id="step1"></li>
                            <li class="etape" data-step-target="step2" id="step2"></li>
                            <li class="etape" data-step-target="step3" id="step3"></li>
                            <li class="etape" data-step-target="step4" id="step4"></li>
                            <li class="etape" data-step-target="step5" id="step5"></li>

                        </ul>
                        <div class="step-content">

                            <div class="step-tab-panel " data-step="step1" id="step-one">
                                <h2 class="step-title">Indiquez-nous votre besoin</h2>
                                <Card-step />
                            </div>
                            <div class="step-tab-panel" data-step="step2" id="step-two">
                                <h2 class="step-title">Indiquez-nous votre statut juridique</h2>
                                <div class="step-card responsive-step-2">
                                    <div class="position-relative w-100">
                                        <span class="field-required hide" id="field-activity">*Champ obligatoire</span>
                                        <input type="text" class="form-control mb-4" name="activity" id="activity" v-model="activity" placeholder="Activité : Banque, BTP, Commerce ...">
                                    </div>

                                    <div class="position-relative w-100">

                                        <div class=" ">
                                            <div class="row mb-3 justify-content-center">
                                                <span class="field-required hide" id="field-company">*Champ
                                                    obligatoire</span>
                                                <div class="col-6 col-md-4">
                                                    <input type="radio" @click="getChecked(true)" name="forme" class="step-card-item" value="SASU"
                                                        id="item-1">
                                                    <label for="item-1 ">SASU</label>
                                                </div>
                                                <div class="col-6 col-md-4">
                                                    <input type="radio" name="forme" class="step-card-item" value="SARL"
                                                        id="item-2">
                                                    <label for="item-2">SARL</label>
                                                </div>
                                                <div class="col-6 col-md-4">
                                                    <input type="radio" name="forme" class="step-card-item" value="SAS"
                                                        id="item-3">
                                                    <label for="item-3">SAS</label>
                                                </div>
                                                <div class="col-6 col-md-4">
                                                    <input type="radio" name="forme" class="step-card-item" value="EURL"
                                                        id="item-4">
                                                    <label for="item-4">EURL</label>
                                                </div>
                                                <div class="col-6 col-md-4">
                                                    <input type="radio" name="forme" class="step-card-item" value="EIRL"
                                                        id="item-5">
                                                    <label for="item-5">EIRL</label>
                                                </div>
                                                <div class="col-6 col-md-4">
                                                    <input type="radio" name="forme" class="step-card-item" value="LTD"
                                                        id="item-6">
                                                    <label for="item-6">LTD</label>
                                                </div>

                                                <div class="col-6 col-md-4">
                                                    <input type="radio" name="forme" class="step-card-item" value="SEP"
                                                        id="item-7">
                                                    <label for="item-7">SEP</label>
                                                </div>
                                                <div class="col-6 col-md-4">
                                                    <input type="radio" name="forme" class="step-card-item" value="SA"
                                                        id="item-8">
                                                    <label for="item-8">SA</label>
                                                </div>
                                                <div class="col-6 col-md-4">
                                                    <input type="radio" name="forme" class="step-card-item" value="GIE"
                                                        id="item-9">
                                                    <label for="item-9">GIE</label>
                                                </div>
                                                <div class="col-6 col-md-12">
                                                    <input type="text" placeholder="Autre..." class="step-card-item">
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="step-tab-panel  documents " data-step="step1" id="step-one">
                                <h2 class="step-title">Documents à nous fournir</h2>
                                <div class="step-card card-step-3">
                                    <div class="form-group mb-4 custom-file-upload">
                                        <div class="label-input-file">
                                            <p class="label">Pièce d’identité recto *


                                                <span>
                                                    <a href="" download="newfilename" target="_blank">
                                                        <img src="'images/icons/account-download.svg" alt="download"  style="height: 15px" class="ml-1 download-icon">
                                                    </a>
                                                </span>

                                            </p>

                                        </div>

                                        <label for="rectoPersonal" class="custom-input-file  input-imagesrectoPersonal">
                                            <span class="text-doc">

                                                Voulez-vous remplacer l'ancien document ?
                                                <input type="hidden" class="existed" value="true" id="rectoPersonaldoc">

                                                <input type="hidden" class="existed" value="false">

                                            </span>
                                            <div>

                                                <img src="/images/icons/download.svg" width="15px"  id="identity-card-download">

                                            </div>
                                        </label>
                                        <input type="file" class="form-control  upload-fileDom upload-filePersonal"
                                            id="rectoPersonal" name="identity-card-recto"
                                            accept="image/*, application/pdf"  ref="file" v-on:change="handleFileUpload()"/>
                                        
                                        <div class="imagesrectoPersonal custom-input-file hide"></div>
                                    </div>
                                    <div class="form-group mb-4 custom-file-upload">
                                        <div class="label-input-file">
                                            <p class="label">Pièce d’identité verso *

                                                <span>
                                                    <a href="" download="newfilename" target="_blank">
                                                        <img src="images/icons/account-download.svg" alt="download"  style="height: 15px" class="ml-1 download-icon">
                                                    </a>
                                                </span>

                                            </p>

                                        </div>

                                        <label for="versoPersonal" class="custom-input-file input-imagesversoPersonal">
                                            <span>


                                                <input type="hidden" class="existed" value="true" id="versoPersonaldoc">

                                                Importer le document
                                                <input type="hidden" class="existed" value="false">

                                            </span>
                                            <div>

                                                <img src="/images/icons/download.svg" width="15px"
                                                    id="identity-cardPersonal">

                                            </div>
                                        </label>
                                        <input type="file" class="form-control upload-fileDom upload-filePersonal"
                                            id="versoPersonal" name="identity-card-verso"
                                            accept="image/*, application/pdf" />
                                        <div class="imagesversoPersonal  custom-input-file hide"></div>
                                    </div>
                                    <div class="form-group mb-4  custom-file-upload">
                                        <div class="label-input-file">
                                            <p class="label">Justificatif de domicile de moins de 3 mois ou attestation
                                                d’hébergement *

                                                <span>
                                                    <a href="" download="newfilename" target="_blank">
                                                        <img src="images/icons/account-download.svg" alt="download"
                                                            style="height: 15px" class="ml-1 download-icon">
                                                    </a>
                                                </span>

                                            </p>

                                        </div>
                                        <label for="proof-address" class="custom-input-file input-imagesproof-address ">
                                            <span>

                                                Voulez-vous remplacer l'ancien document ?
                                                <input type="hidden" class="existed" value="true" id="proof-addressdoc">

                                                Importer le document
                                                <input type="hidden" class="existed" value="false">

                                            </span>
                                            <div>

                                                <img src="/images/icons/download.svg" width="15px"  id="proof-address-download">

                                            </div>

                                        </label>
                                        <input type="file" class="form-control  upload-fileDom" id="proof-address"
                                            name="proof-address" accept="image/*, application/pdf" />
                                        <div class="imagesproof-address custom-input-file hide"></div>
                                    </div>

                                    <div class="form-group mb-4 ">
                                        <div class="label-input-file">
                                            <p class="label">Kbis ou justificatif attestant de la création de la structure *

                                                <span>
                                                    <a href="" download="newfilename" target="_blank">
                                                        <img src="images/icons/account-download.svg" alt="download"
                                                            style="height: 15px" class="ml-1 download-icon">
                                                    </a>
                                                </span>

                                            </p>

                                        </div>
                                        <label for="kbis" class="custom-input-file input-imageskbis">
                                            <span>

                                                Voulez-vous remplacer l'ancien document ?
                                                <input type="hidden" class="existed" value="true" id="kbisdoc">

                                                Importer le document
                                                <input type="hidden" class="existed" value="false">


                                            </span>
                                            <div>
                                                <img src="/images/icons/download.svg" width="15px" id="kbis-download">

                                            </div>
                                        </label>
                                        <input type="file" class="form-control  upload-fileDom" id="kbis" name="kbis"
                                            accept="image/*, application/pdf" />
                                        <div class="imageskbis custom-input-file hide"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="step-tab-panel remarque" data-step="step4" id="step-foor">
                                <h2 class="step-title">Remarques</h2>
                                <div class="step-card card-step-3 ">
                                    <P class="note-title">Autres spécificités</P>
                                    <P class="note-text">Indiquez ci-dessous vos demandes particulières ou autres
                                        remarques.</P>
                                    <div class="form-group mb-4">
                                        <div class="col">
                                            <textarea id="description" maxlength="224" rows="10" placeholder="Remarques"
                                                class="step-card-item step-card-item-textarea" v-model="note"
                                                name="description"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="step-tab-panel" data-step="step5" id="last-step">
                                <h2 class="step-title">Récapitulatif</h2>
                                <div class="step-card card-step-4">
                                    <input type="hidden" value="" name="subscription">

                                    <input type="hidden" value="" name="hasRedirection">

                                    <div class="title-recap {% if hotlinePrice != null and priceDomiciliation == null  %}title-recap-phone {% elseif  hotlinePrice != null and priceDomiciliation != null %}title-recap-phone-dom{% endif %}">
                                       {{ this.data.dom }} 
                                    </div>
                                    <div class="row table-recap">
                                        <div class="col-12 col-sm-8 col-md-6 ">
                                            <div class="mb-2 border-responsive ">
                                                <span class="td-name">{{this.user.lastname}}</span> {{this.user.firstname}}<br>
                                                <span>{{this.user.email}}</span><br>
                                                <span>{{this.user.phone}}</span><br>
                                            </div>
                                            <div class="border-responsive ">

                                                <span class="td-title">Statut juridique</span><br>
                                                <span>Activité</span><br>
                                                <span id="activityrecap">
                                                    Banque
                                                </span><br>



                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-8 col-md-6 col-left">
                                            <div class="mb-2 border-responsive ">
                                                <span class="td-title">Besoin</span><br>

                                                <span>Permanence téléphonique</span>

                                            </div>
                                            <div class="border-responsive ">


                                                <span class="td-title ">Option</span><br>
                                                
                                                <p :class=" [this.data.scan ? 'proScan' : 'proScan noproScan']">Option scan pro <span :class=" [this.data.scan ? 'proScan' : 'proScan noproScan']">90 € </span> <img  :src=" this.data.scan ? imgScan : imgCroix " > </p>

                                               


                                            </div>

                                        </div>

                                    </div>
                                    <div class="table-recap">
                                        <p class="text-md-center text-start td-name">Autres spécificités</p>
                                        <p class="text-md-center text-start">Indiquez ci-dessous vos demandes
                                            particulières ou autres remarques.</p>
                                        
                                        <textarea id="descriptio" maxlength="224" rows="10" placeholder="Remarques" class="step-card-item step-card-item-textarea" v-model="note"  name="description"  readonly>
                                        </textarea>

                                        
                                    </div>

                                    <div class="price-recap">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="ml-2 d-flex mb-3 justify-content-center">

                                                    <p class="text-price">
                                                        {{ this.data.dom }}
                                                    </p>
                                                    <span class="price">

                                                        {{ this.data.price }} € HT
                                                        <input type="hidden" value="" name="hotlinePrice">
                                                        <input type="hidden" value="" name="maxCall">
                                                    </span>
                                                </div>

                                                <div class="ml-2 d-flex mb-3 justify-content-center" v-if="this.data.form === 'domHt'">

                                                    <p class="text-price" >
                                                        Permanence téléphonique
                                                    </p>
                                                    <span class="price" >

                                                        {{this.data.priceHotline}}€ HT
                                                        <input type="hidden" value="" name="hotlinePrice">
                                                        <input type="hidden" value="" name="maxCall">
                                                    </span>
                                                </div>



                                                <div class="ml-2 d-flex mb-3 justify-content-center" v-if="this.data.dom !== 'permanence téléphonique'">

                                                    <p class="text-price">Option scan pro</p>
                                                    <span class="price" v-if="this.data.scan">
                                                        {{this.data.scanPrice}} € HT
                                                        <input type="hidden" value="" name="proScan">
                                                    </span>
                                                    <span class="price" v-else>
                                                        0 € HT
                                                    </span>

                                                    
                                                </div>

                                                <div class="ml-2 d-flex mb-3 justify-content-center position-relative">
                                                    <p class="text-price">Prix total</p>
                                                    <span class="price" >
                                                        {{this.data.total}} € HT
                                                    </span>
                                                   
                                                    <span class="tvaprice">TVA {{tax}} % :
                                                         {{this.data.priceTax }}€ 
                                                    </span>
                                                </div>
                                                <div class="ml-2 flex-wrap d-flex mb-3 align-items-center justify-content-center ">
                                                    <p class="price-total col-8 col-md-3">Prix total</p>
                                                    <p class="text-price-total col-8 col-md-4">
                                                        {{this.data.totalPriceTax}}
                                                        <input type="hidden" name="priceTotal" value="">
                                                        <span>€</span>
                                                    </p>
                                                    <p class="month col-8 col-md-3">TTC</p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button type="button" class="btn " @click="domiciliation(); document()" id="submit-btn">Soumettre ma demande</button>
                            </div>


                        </div>

                        <div class="step-footer">
                            <button type="button" data-step-action="prev" class="btn step-btn btn-prev">Retour</button>
                            <button type="button" :disabled="this.continue == 1"  data-step-action="next" class="btn step-btn continue" id="continue-btn">Continuer</button>
                            
                            
                        </div>

                    </div>
                </form>
            </div>
        </div>
    </section>

</template>

<script>

import  CardStep from "../components/Cardstep.vue"
import DomiciliationService from '../services/domiciliation.js'
import DocumentService from '../services/document.js'

const vuex = localStorage.getItem('vuex');
let formData = new FormData();
const user = JSON.parse(vuex)?.auth?.user?.data ;

export default {
    name: "DomiciliationFormView",
    components: {
        CardStep,
    },
    data: function () {
		
		return {
            booking_dom_right: require('../assets/domiciliation/booking-dom-right.svg'),
            booking_dom_left: require('../assets/domiciliation/booking-dom-left.svg'),
            continue: false,
            imgScan: require('../assets/icons/checked.svg'),
            imgCroix: require('../assets/icons/account-no-check-room.svg'),
            note:"",
            user: "",
            data:"",
            tax: 0,
            activity:"",
            file: "",
            domData: {
                idOwner: user.id,
                idTeam : 2,
                type : "work-hard-1",
                activity : this.activity,
                forme : "su",
                description : this.note,
                subscription : "month",
                hasRedirection : 1,
                hotlinePrice: 34,
                maxCall :13,
                domiciliationPrice :500,
                proScanPrice : 120,
                totalPrice : 567,
                totalPriceExTax:1000,
                hotlinePriceSlice:23
            },
            docData: {
                name:'dom',
                customer: user.id,
                type: "dom",
                typeBooking: 'domdemande',
                file: []
               
            }
            
           

		}
		
	}, 
    methods: {
        handleFileUpload(){
            
            this.docData.file.push(this.$refs.file.files[0]);
           
            formData.append('file', this.docData.file);
           

        },

        async document() {
            console.log(this.docData.file);
            this.docData.file = formData
            DocumentService.document(this.docData);

        },

        getChecked(value){
            console.log(value);
        },
        getTaxInfo(){
            DomiciliationService.getTax()
                .then(response => {
					this.tax = response.data;
					
				})
				.catch(e => {
					console.log(e);
			});
            
        },
        async domiciliation() {
            DomiciliationService.domiciliation(this.domData)

        },
        
       
    },
    mounted() {
        const data = JSON.parse(localStorage.getItem('dom'));
        this.data = data ;
        this.getTaxInfo();
        this.user = user
        window.$('#wizard-form').steps({
            onFinish: function () {
                alert('complete');
            }
        });
    },
}
</script>
<template>
    <div class="modal fade" id="agreeModal" data-backdrop="static" data-keyboard="false" tabindex="-1"
        aria-labelledby="staticBackdrop" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="mx-auto" id="staticBackdropLabel">Mentions légales</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="py-4 px-3 overflow-auto">
                    <h6>1. Pr&eacute;sentation&nbsp;du&nbsp;site&nbsp;</h6>
                    <p>En vertu de l&apos;article 6 de la loi n&deg; 2004-575 du 21 juin 2004 pour la confiance dans
                        l&apos;&eacute;conomie num&eacute;rique, il est pr&eacute;cis&eacute; aux utilisateurs du
                        site www.decliccenter.fr l&apos;identit&eacute; des diff&eacute;rents
                        intervenants dans le cadre de sa r&eacute;alisation et de son suivi :&nbsp;</p>

                    <p>Propri&eacute;taire :&nbsp;&nbsp;<br>Declic&nbsp;Gestion&nbsp;(SIRET
                        :&nbsp;501&nbsp;760&nbsp;383&nbsp;00023)&nbsp;11,
                        rue F&eacute;lix&nbsp;Poyez&nbsp;77000 Melun&nbsp;&nbsp;<br>Cr&eacute;ateur : <a
                            href="https://eyefiz.com/" rel="noreferrer noopener" target="_blank">Eyefiz
                            Digital</a>&nbsp;<br>Responsable publication :&nbsp;Laurent
                        LAHAULLE&nbsp;&ndash;&nbsp;l.lahaulle@agiliwork.com&nbsp;<br>Le responsable publication est
                        une personne physique ou une personne morale.&nbsp;<br>Webmaster : Eyefiz &ndash;
                        contact@eyefiz.com&nbsp;<br>H&eacute;bergeur : OVH &ndash; 2 Rue&nbsp;Kellerman&nbsp;59100
                        ROUBAIX&nbsp;</p>

                    <h6>2. Conditions&nbsp;g&eacute;n&eacute;rales&nbsp;d&rsquo;utilisation du site et des services
                        propos&eacute;s&nbsp;</h6>
                    <p>L&rsquo;utilisation du site www.decliccenter.fr implique l&rsquo;acceptation pleine et
                        enti&egrave;re des conditions g&eacute;n&eacute;rales d&rsquo;utilisation ci-apr&egrave;s
                        d&eacute;crites. Ces conditions d&rsquo;utilisation sont susceptibles d&rsquo;&ecirc;tre
                        modifi&eacute;es ou compl&eacute;t&eacute;es &agrave; tout moment, les utilisateurs du
                        site www.decliccenter.fr sont donc invit&eacute;s &agrave; les consulter
                        de mani&egrave;re r&eacute;guli&egrave;re.&nbsp;</p>

                    <p>Ce site est normalement accessible &agrave; tout moment aux utilisateurs. Une interruption
                        pour raison de maintenance technique peut &ecirc;tre toutefois d&eacute;cid&eacute;e
                        par&nbsp;D&eacute;clic Center, qui s&rsquo;efforcera alors de communiquer
                        pr&eacute;alablement aux utilisateurs les dates et heures de l&rsquo;intervention.&nbsp;</p>


                    <p>Le site www.decliccenter.fr est mis &agrave; jour
                        r&eacute;guli&egrave;rement. De la m&ecirc;me fa&ccedil;on, les mentions l&eacute;gales
                        peuvent &ecirc;tre modifi&eacute;es &agrave; tout moment : elles s&rsquo;imposent
                        n&eacute;anmoins &agrave; l&rsquo;utilisateur qui est invit&eacute; &agrave; s&rsquo;y
                        r&eacute;f&eacute;rer le plus souvent possible afin d&rsquo;en prendre connaissance.&nbsp;
                    </p>
                    <h6>3. Description des services fournis&nbsp;</h6>

                    <p>Le site www.decliccenter.fr a pour objet de fournir une information
                        concernant l&rsquo;ensemble des activit&eacute;s de la soci&eacute;t&eacute;.&nbsp;</p>

                    <p>D&eacute;clic Center&nbsp;s&rsquo;efforce de fournir sur le site www.decliccenter.fr des informations aussi pr&eacute;cises que
                        possible. Toutefois, il ne pourra &ecirc;tre tenue responsable des omissions, des
                        inexactitudes et des carences dans la mise &agrave; jour, qu&rsquo;elles soient de son fait
                        ou du fait des tiers partenaires qui lui fournissent ces informations.&nbsp;</p>


                    <p>Toutes les informations indiqu&eacute;es&nbsp;sur le site www.decliccenter.fr sont donn&eacute;es
                        &agrave; titre indicatif, et sont susceptibles d&rsquo;&eacute;voluer. Par ailleurs, les
                        renseignements figurant sur le site www.decliccenter.fr ne sont pas
                        exhaustifs. Ils sont donn&eacute;s sous r&eacute;serve de modifications ayant
                        &eacute;t&eacute; apport&eacute;es depuis leur mise en ligne.&nbsp;</p>
                    <h6>4. Limitations contractuelles sur les donn&eacute;es techniques&nbsp;</h6>

                    <p>Le site utilise la technologie JavaScript.&nbsp;</p>


                    <p>Le site Internet ne pourra &ecirc;tre tenu responsable de dommages mat&eacute;riels
                        li&eacute;s &agrave; l&rsquo;utilisation du site. De plus, l&rsquo;utilisateur du site
                        s&rsquo;engage &agrave; acc&eacute;der au site en utilisant un mat&eacute;riel
                        r&eacute;cent, ne contenant pas de virus et avec un navigateur de derni&egrave;re
                        g&eacute;n&eacute;ration mis&nbsp;&agrave;&nbsp;jour.&nbsp;</p>

                    <h6>5. Propri&eacute;t&eacute; intellectuelle et contrefa&ccedil;ons&nbsp;</h6>

                    <p>D&eacute;clic Center&nbsp;est propri&eacute;taire des droits de propri&eacute;t&eacute;
                        intellectuelle ou d&eacute;tient les droits d&rsquo;usage sur tous les
                        &eacute;l&eacute;ments accessibles sur le site, notamment les textes, images, graphismes,
                        logo, ic&ocirc;nes, sons, logiciels.&nbsp;</p>

                    <p>Toute reproduction, repr&eacute;sentation, modification, publication, adaptation de tout ou
                        partie des &eacute;l&eacute;ments du site, quel que soit le moyen ou le
                        proc&eacute;d&eacute; utilis&eacute;, est interdite, sauf autorisation &eacute;crite
                        pr&eacute;alable de :&nbsp;D&eacute;clic Center.&nbsp;</p>

                    <p>Toute exploitation non autoris&eacute;e du site ou de l&rsquo;un quelconque des
                        &eacute;l&eacute;ments qu&rsquo;il contient sera consid&eacute;r&eacute;e comme constitutive
                        d&rsquo;une contrefa&ccedil;on et poursuivie conform&eacute;ment aux dispositions des
                        articles L.335-2 et suivants du Code de Propri&eacute;t&eacute; Intellectuelle.&nbsp;</p>

                    <h6>6. Limitations de responsabilit&eacute;&nbsp;</h6>

                    <p>D&eacute;clic Center&nbsp;ne pourra &ecirc;tre tenue responsable des dommages directs et
                        indirects caus&eacute;s au mat&eacute;riel de l&rsquo;utilisateur, lors de
                        l&rsquo;acc&egrave;s au site&nbsp;www.decliccenter.fr, et r&eacute;sultant soit de
                        l&rsquo;utilisation d&rsquo;un mat&eacute;riel ne r&eacute;pondant pas aux
                        sp&eacute;cifications indiqu&eacute;es au point 4, soit de l&rsquo;apparition d&rsquo;un bug
                        ou d&rsquo;une incompatibilit&eacute;.&nbsp;</p>

                    <p>D&eacute;clic Center&nbsp;ne pourra &eacute;galement &ecirc;tre tenue responsable des
                        dommages indirects (tels par exemple qu&rsquo;une perte de march&eacute; ou perte
                        d&rsquo;une chance) cons&eacute;cutifs &agrave; l&rsquo;utilisation du site www.decliccenter.fr.&nbsp;</p>

                    <p>Des espaces interactifs (possibilit&eacute; de poser des questions dans l&rsquo;espace
                        contact) sont &agrave; la disposition des utilisateurs.&nbsp;D&eacute;clic Center&nbsp;se
                        r&eacute;serve le droit de supprimer, sans mise en demeure pr&eacute;alable, tout contenu
                        d&eacute;pos&eacute; dans cet espace qui contreviendrait &agrave; la l&eacute;gislation
                        applicable en France, en particulier aux dispositions relatives &agrave; la protection des
                        donn&eacute;es. Le cas &eacute;ch&eacute;ant,&nbsp;D&eacute;clic Center&nbsp;se
                        r&eacute;serve &eacute;galement la possibilit&eacute; de mettre en cause la
                        responsabilit&eacute; civile et/ou p&eacute;nale de l&rsquo;utilisateur, notamment en cas de
                        message &agrave; caract&egrave;re raciste, injurieux, diffamant, ou pornographique, quel que
                        soit le support utilis&eacute; (texte, photographie&hellip;).&nbsp;</p>

                    <h6>7. Gestion des donn&eacute;es personnelles&nbsp;</h6>

                    <p>En France, les donn&eacute;es personnelles sont notamment prot&eacute;g&eacute;es par la loi
                        n&deg; 78-87 du 6 janvier 1978, la loi n&deg; 2004-801 du 6 ao&ucirc;t 2004, l&apos;article
                        L. 226-13 du Code p&eacute;nal et la Directive Europ&eacute;enne du 24 octobre 1995.&nbsp;
                    </p>

                    <p>A l&apos;occasion de l&apos;utilisation du site www.decliccenter.fr,
                        peuvent&nbsp;&ecirc;tre&nbsp;recueillies : l&apos;URL des liens par
                        l&apos;interm&eacute;diaire desquels l&apos;utilisateur a acc&eacute;d&eacute; au site www.decliccenter.fr, le fournisseur d&apos;acc&egrave;s de
                        l&apos;utilisateur, l&apos;adresse de protocole Internet (IP) de l&apos;utilisateur.&nbsp;
                    </p>

                    <p>En tout &eacute;tat de cause&nbsp;D&eacute;clic Center&nbsp;ne collecte des informations
                        personnelles relatives &agrave; l&apos;utilisateur que pour le besoin de certains services
                        propos&eacute;s par le site www.decliccenter.fr. L&apos;utilisateur fournit ces informations en
                        toute connaissance de cause, notamment lorsqu&apos;il proc&egrave;de par lui-m&ecirc;me
                        &agrave; leur saisie. Il est alors pr&eacute;cis&eacute; &agrave; l&apos;utilisateur du
                        site www.decliccenter.fr l&rsquo;obligation ou non de fournir ces
                        informations.&nbsp;</p>

                    <p>Conform&eacute;ment aux dispositions des articles 38 et suivants de la loi 78-17 du 6 janvier
                        1978 relative &agrave; l&rsquo;informatique, aux fichiers et aux libert&eacute;s, tout
                        utilisateur dispose d&rsquo;un droit d&rsquo;acc&egrave;s, de rectification et
                        d&rsquo;opposition aux donn&eacute;es personnelles le concernant, en effectuant sa demande
                        &eacute;crite et sign&eacute;e, accompagn&eacute;e d&rsquo;une copie du titre
                        d&rsquo;identit&eacute; avec signature du titulaire de la pi&egrave;ce, en pr&eacute;cisant
                        l&rsquo;adresse &agrave; laquelle la r&eacute;ponse doit &ecirc;tre envoy&eacute;e.&nbsp;
                    </p>

                    <p>Aucune information personnelle de l&apos;utilisateur du site www.decliccenter.fr n&apos;est publi&eacute;e
                        &agrave; l&apos;insu
                        de l&apos;utilisateur, &eacute;chang&eacute;e, transf&eacute;r&eacute;e, c&eacute;d&eacute;e
                        ou vendue sur un support quelconque &agrave; des tiers. Seule l&apos;hypoth&egrave;se du
                        rachat de&nbsp;D&eacute;clic Center&nbsp;et de ses droits permettrait la transmission des
                        dites informations &agrave; l&apos;&eacute;ventuel acqu&eacute;reur qui serait &agrave; son
                        tour tenu de la m&ecirc;me obligation de conservation et de modification des donn&eacute;es
                        vis &agrave; vis de l&apos;utilisateur du site www.decliccenter.fr.&nbsp;</p>

                    <p>Les bases de donn&eacute;es sont prot&eacute;g&eacute;es par les dispositions de la loi du
                        1er juillet 1998 transposant la directive 96/9 du 11 mars 1996 relative &agrave; la
                        protection juridique des bases de donn&eacute;es.&nbsp;</p>

                    <h6>8. Liens hypertextes et cookies&nbsp;</h6>

                    <p>Le site www.decliccenter.fr contient un certain nombre de liens hypertextes
                        vers d&rsquo;autres sites, mis en place avec l&rsquo;autorisation de&nbsp;D&eacute;clic
                        Center. Cependant,&nbsp;D&eacute;clic Center&nbsp;n&rsquo;a pas la possibilit&eacute; de
                        v&eacute;rifier le contenu des sites ainsi visit&eacute;s, et n&rsquo;assumera en
                        cons&eacute;quence aucune responsabilit&eacute; de ce fait.&nbsp;</p>

                    <p>La navigation sur le site www.decliccenter.fr est susceptible de provoquer
                        l&rsquo;installation de cookie(s) sur l&rsquo;ordinateur de l&rsquo;utilisateur. Un cookie
                        est un fichier de petite taille, qui ne permet pas l&rsquo;identification de
                        l&rsquo;utilisateur, mais qui enregistre des informations relatives &agrave; la navigation
                        d&rsquo;un ordinateur sur un site. Les donn&eacute;es ainsi obtenues visent &agrave;
                        faciliter la navigation ult&eacute;rieure sur le site, et ont &eacute;galement vocation
                        &agrave; permettre diverses mesures de fr&eacute;quentation.&nbsp;</p>

                    <p>Le refus d&rsquo;installation d&rsquo;un cookie peut entra&icirc;ner
                        l&rsquo;impossibilit&eacute; d&rsquo;acc&eacute;der &agrave; certains services.
                        L&rsquo;utilisateur peut toutefois configurer son ordinateur de la mani&egrave;re suivante,
                        pour refuser l&rsquo;installation des cookies :&nbsp;</p>

                    <p>Sous Chrome :&nbsp;cliquez en&nbsp;haut &agrave; droite du navigateur sur le pictogramme de
                        menu (symbolis&eacute; par trois lignes horizontales). S&eacute;lectionnez
                        Param&egrave;tres. Cliquez sur Afficher les param&egrave;tres avanc&eacute;s. Dans la
                        section &quot;Confidentialit&eacute;&quot;, cliquez sur pr&eacute;f&eacute;rences. Dans
                        l&apos;onglet &quot;Confidentialit&eacute;&quot;, vous pouvez bloquer les cookies.&nbsp;</p>

                    <p>Sous Internet Explorer : onglet outil (pictogramme en forme de rouage en
                        haut&nbsp;&agrave;&nbsp;droite) / options internet. Cliquez sur Confidentialit&eacute; et
                        choisissez Bloquer tous les cookies. Validez sur Ok.&nbsp;</p>

                    <p>Sous Firefox : en haut de la fen&ecirc;tre du navigateur, cliquez sur le bouton Firefox, puis
                        aller dans l&apos;onglet Options. Cliquer sur l&apos;onglet Vie priv&eacute;e.
                        Param&eacute;trez les R&egrave;gles de conservation sur : utiliser les param&egrave;tres
                        personnalis&eacute;s pour l&apos;historique. Enfin d&eacute;cochez-la pour d&eacute;sactiver
                        les cookies.&nbsp;</p>

                    <p>Sous Safari : Cliquez en haut &agrave; droite du navigateur sur le pictogramme de menu
                        (symbolis&eacute; par un rouage). S&eacute;lectionnez Param&egrave;tres. Cliquez sur
                        Afficher les param&egrave;tres avanc&eacute;s. Dans la section
                        &quot;Confidentialit&eacute;&quot;, cliquez sur Param&egrave;tres de contenu. Dans la
                        section &quot;Cookies&quot;, vous pouvez bloquer les cookies.&nbsp;</p>

                    <h6>9. Droit applicable et attribution de juridiction&nbsp;</h6>
                    <p>Tout litige en relation avec l&rsquo;utilisation du site www.decliccenter.fr est soumis au droit
                        fran&ccedil;ais. Il est fait attribution exclusive de juridiction aux tribunaux
                        comp&eacute;tents de Paris.&nbsp;</p>

                    <h6>10. Les principales lois concern&eacute;es&nbsp;</h6>

                    <p>Loi n&deg; 78-17 du 6 janvier 1978, notamment modifi&eacute;e par la loi n&deg; 2004-801 du 6
                        ao&ucirc;t 2004 relative &agrave; l&apos;informatique, aux fichiers et aux
                        libert&eacute;s.&nbsp;</p>

                    <p>Loi n&deg; 2004-575 du 21 juin 2004 pour la confiance dans l&apos;&eacute;conomie
                        num&eacute;rique.&nbsp;</p>

                    <h6>11. Lexique&nbsp;</h6>

                    <p>Utilisateur : Internaute se connectant, utilisant le site susnomm&eacute;.&nbsp;</p>

                    <p>Informations personnelles : &laquo; les informations qui permettent, sous quelque forme que
                        ce soit, directement ou non, l&apos;identification des personnes physiques auxquelles elles
                        s&apos;appliquent &raquo; (article 4 de la loi n&deg; 78-17 du 6 janvier 1978).&nbsp;</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "TermsModal",
    data() {
        return {

        };
    },
}
</script>

<style lang="scss">
#agreeModal {
    a {
        text-decoration: none;
        color: black;
    }
}
</style>
import axios from 'axios';
class GaleryService {

    getGalery() {
        return axios.get("/centreo/gallery/2");
    }

}

export default new GaleryService();

import axios from 'axios';
class AuthService {
    isAuth() {
        return localStorage.getItem('token') !== null;
    }
    login(user) {
        return axios
            .post('/api/login_check', {
                email: user.email,
                password: user.password,
                _remember_me: user.rememberMe
            })
            .then(response => {
                if (response.data.accessToken) {
                    localStorage.setItem('user', JSON.stringify(response.data));
                }

                return response.data;
            });
    }

    logout() {
        localStorage.removeItem('user');
    }

    register(user) {
        return axios.post('/centreo/registrer', {
            email: user.email,
            password: user.password,
            firstname: user.firstname,
            lastname: user.lastname,
            phone: user.phone,
            siret: user.siret,
            isPro: user.isPro,
            companyName: user.companyName,
            dateOfBirth: user.dateOfBirth,
            address: user.address,
            team: process.env.VUE_APP_TEAM,
        });
    }
    forgotPassword(user) {
        return axios.post('/api/forgot-password', {
            email: user.email
        });
    }
}

export default new AuthService();

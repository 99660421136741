import {
  createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import $ from "expose-loader?exposes=$,jQuery!jquery";
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css'
import 'jquery.steps/dist/jquery-steps.css';
import '@fortawesome/fontawesome-free/css/all.css'
import _ from 'lodash';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

window.$ = $;
window.jQuery = $;
window.$_ = _;

const app = createApp(App)
app.use(store).use(router).use(VueSweetalert2).mount('#app')

axios.defaults.baseURL = (process.env.NODE_ENV === 'production' && window.location.hostname !== 'localhost') ? process.env.VUE_APP_API_URL : 'https://localhost:8000/';

console.log(process.env.NODE_ENV);

import 'bootstrap/dist/js/bootstrap.bundle.js'
import '@fortawesome/fontawesome-free/js/all.js'
import '@popperjs/core'
import 'jquery-nice-select/js/jquery.nice-select.js'
import 'jquery.steps/dist/jquery-steps.js';
import './registerServiceWorker'

$('.alert').alert();
$('select').niceSelect();
<template>
  <header class="header-wrap">
    <nav class="container-full navbar fixed-top navbar-expand-lg navbar-light bg-white">
      <div class="container-fluid">
        <a class="navbar-brand" href="/">
          <img :src="logo" class="logo">
        </a>
        <a id="contactBtn" href="#contactForm" class="btn" :class="{ 'hide': !status }">Nous contacter</a>
        <!-- <button class="navbar-toggler d-lg-none border-0" type="button" data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false"
          aria-label="Toggle navigation">
          <div id="nav-icon2" :onclick="toggleMenu">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
          <ul class="main-ul navbar-nav mx-auto">
            <li class="nav-item me-4">
              <router-link class="nav-link ps-0 pb-0 mb-0" to="/">Accueil</router-link>
            </li>
            <li class="nav-item me-4">
              <router-link class="nav-link ps-0 pb-0 mb-0" to="/notre-centre">Notre centre</router-link>
            </li>
            <li class="nav-item me-4">
              <router-link class="nav-link ps-0 pb-0 mb-0" to="/bureaux">Bureaux</router-link>
            </li>
            <li class="nav-item me-4">
              <router-link class="nav-link ps-0 pb-0 mb-0" to="/domiciliation">Domiciliation</router-link>
            </li>
            <li class="nav-item me-4">
              <router-link class="nav-link ps-0 pb-0 mb-0" to="/galerie">Galerie</router-link>
            </li>
          </ul>

          <span v-if="currentUser != null" class="px-md-2">Bonjour {{ currentUser.firstname }}</span>
          <div v-if="currentUser != null" id="headerDropdown" class="dropdown border-gradient">
            <button type="button" class="btn dropdown" href="#" role="button" id="dropdownUser"
              data-bs-toggle="dropdown" aria-expanded="false">
              <div class="d-flex align-content-center align-items-center">
                <img :src="userIco" alt="">
              </div>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMUser">
              <li class="dropdown-item">
                <router-link class="nav-link" to="/">Voir le profile</router-link>
              </li>
              <li class="dropdown-item">
                <span class="nav-link" @click="this.logOut">Se déconnecter</span>
              </li>
            </ul>
          </div>
          <ul v-else class="main-ul navbar-nav">
            <li class="nav-item d-flex justify-content-end border-gradient border-gradient-purple">
              <router-link to="/auth"
                class="btn nav-link login-register d-flex align-items-center">
                <span class="px-md-2 text-dark active">Connexion/Inscription</span>
              </router-link>
            </li>
          </ul>
          <button type="buton" class="btn nav-link py-0 h-100 ms-3" data-bs-toggle="modal" data-bs-target="#contact">
            <img class="btn call-btn" :src="viber" alt="appel">
          </button>
        </div> -->
      </div>
    </nav>
  </header>
</template>

<script>
import logo from '@/assets/header/logo.svg'
import viber from '@/assets/header/viber.svg'
import userIco from '@/assets/icons/userLogo.svg'

export default {
  name: 'HeaderNav',
  data: function () {
    return {
      logo: logo,
      viber: viber,
      userIco: userIco,
      status: false
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user?.data;
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    toggleMenu() {
      var element = document.getElementById("nav-icon2");
      element.classList.toggle("open");
    },
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/auth');
    },
    handleScroll() {
      if(window.scrollY >= 800)
          this.status = true
      else
      this.status = false
    }
  }
  ,
}
</script>
<template>
    <div class="room-card">
        <div class="img">
            <img v-bind:src="'https://www.centreo-beta.fr/images/place/'+ image">
        </div>
        <div class="info">
            <span class="d-inline-block">
                <div class="available" tabindex="0" data-bs-toggle="tooltip" data-bs-placement="top"
                    data-bs-content="Disponible aux dates renseignées"></div>
            </span>
            <div class="available"></div>
            <h3 class="name">
                {{office.name}}
            </h3>

            <div class="info-item">
                <img :src="DesktopTower" alt="room-size" class="card-icon">
                <span v-if="office.type === 'temporary'">Temporaire</span>
                <span v-else-if="office.type === 'shared'">Partagé</span>
                <span v-else-if="office.type === 'permanent'">Permanent</span>
            </div>

            <div class="info-item">
                <img :src="roomSize" alt="room-size" class="card-icon">
                {{office.surface}} m<sup>2</sup>
            </div>
            <div class="info-item">
                <img :src="cardPeople" alt="people" class="card-icon">
                {{ office.nbPeople }} personnes
            </div>
            <div class="info-item price">
                <span class="from">À partir de </span>
                <span class="amount">{{ office.permanentTariff }}&nbsp;&euro;</span> <span class="ht">HT/mois</span>
            </div>
            <div class="more">
                <router-link :to="{ name: 'office', params:{ slug: office.slug } }" class="btn btn-more">Voir plus</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import cardDimension from '@/assets/offices/card-dimension.svg'
import cardPeople from '@/assets/offices/card-people.svg'
import DesktopTower from '@/assets/offices/DesktopTower.png'
import roomSize from '@/assets/offices/surface.svg'

export default {
    name: "OfficeComponent",
    props: {
        office: Object,
    },
    data() {
        return {
            image: this.office?.images[Math.floor(Math.random() * this.office?.images.length)]?.name ?? 'default.png',
            cardDimension,
            cardPeople,
            DesktopTower,
            roomSize
        };
    }, mounted() {

    }

};
</script>
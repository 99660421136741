<template>
    <section id="auth-section" class="row justify-content-center align-items-center">
        <div class="modal-auth col-11 col-md-4" id="login-register">
            <div class="modal-auth-dialog modal-auth-dialog-centered" role="document">
                <div id="auth-error" class="alert text-center w-100 fade" :class="{ 'show': status.status }"
                    role="alert">
                    <span class="error-text">{{ status.message }}</span>
                </div>
                <div class="modal-auth-content">
                    <div class="modal-auth-body">
                        <div class="row">
                            <div class="col-lg-12">
                                <h5 class="modal-auth-title">Bienvenue chez Déclic Center</h5>
                            </div>
                        </div>
                        <div class="row" id="content-register">
                            <div class="register-content-wrap">
                                <ul class="nav nav-pills login-register-tabs d-flex justify-content-center"
                                    id="pills-tab" role="tablist">
                                    <li class="nav-item login">
                                        <a class="nav-link active" id="pills-login-tab" data-bs-toggle="pill"
                                            data-bs-target="#pills-login" role="tab" aria-controls="pills-login"
                                            aria-selected="true" @click="tabNav('Connexion')"
                                            ref="loginToggle">Connexion</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="pills-register-tab" data-bs-toggle="pill"
                                            data-bs-target="#pills-register" role="tab" aria-controls="pills-register"
                                            aria-selected="false" @click="tabNav('Inscription')">Inscription</a>
                                    </li>
                                </ul>
                                <div class="tab-content mobile-margin-bottom" id="pills-tabContent">
                                    <div class="tab-pane fade show active" id="pills-login" role="tabpanel"
                                        aria-labelledby="pills-login-tab">
                                        <div class="container justify-content-center">
                                            <div class="form">
                                                <div class="row justify-content-center">
                                                    <div class="col-12 mt-1">
                                                        <div class="form-group"
                                                            :class="{ error: vLogin$.email.$errors.length }">
                                                            <input v-model="stateLogin.email" type="email" id="username"
                                                                name="_username" class="form-control" required
                                                                placeholder="Adresse mail" @blur="vLogin$.email.$touch">
                                                            <div class="input-errors"
                                                                v-for="error of vLogin$.email.$errors"
                                                                :key="error.$uid">
                                                                <span class="error-text">{{ error.$message }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row justify-content-center">
                                                    <div class="col-12 mt-1">
                                                        <div class="form-group position-relative"
                                                            :class="{ error: vLogin$.password.$errors.length }">
                                                            <input v-model="stateLogin.password"
                                                                :type="passwordFieldType" id="password" name="_password"
                                                                class="form-control" placeholder="Mot de passe" required
                                                                @blur="vLogin$.password.$touch">
                                                            <button type="button"
                                                                class="passwordbutton btn position-absolute"
                                                                @click="passwordVisibility">
                                                                <span v-if="!showPassword"><i
                                                                        class="fas fa-eye"></i></span>
                                                                <span v-else><i class="fas fa-eye-slash"></i></span>
                                                            </button>
                                                            <div class="input-errors"
                                                                v-for="error of vLogin$.password.$errors"
                                                                :key="error.$uid">
                                                                <span class="error-text">{{ error.$message }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row justify-content-between">
                                                    <div class="col-6">
                                                        <div class="form-check">
                                                            <input class="form-check-input" name="_remember_me"
                                                                v-model="remember_me" type="checkbox" :value="false"
                                                                id="_remember_me">
                                                            <label class="form-check-label" for="_remember_me">
                                                                Se souvenir de moi
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-6 text-end">
                                                        <router-link class="btn p-0" id="forgot-password"
                                                            to="/forgot-password">Mot de passe oublié&nbsp;&quest;
                                                        </router-link>
                                                    </div>
                                                </div>
                                                <div class="row justify-content-center">
                                                    <div class="col-12 text-center">
                                                        <button type="button" @click="login"
                                                            class="btn form-btn btn-submit login-register-submit mt-2">
                                                            <div v-if="loading"
                                                                class="spinner-border spinner-border-sm text-white"
                                                                :class="{ 'hide': !loading }" role="status">
                                                                <span class="sr-only"></span>
                                                            </div>
                                                            <span v-else>Me connecter</span>

                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="pills-register" role="tabpanel"
                                        aria-labelledby="pills-register-tab">
                                        <div class="container justify-content-center">
                                            <form action="registration" method="post" id="form-register">
                                                <div class="row justify-content-center">
                                                    <div class="col-12 mt-1 mb-3"
                                                        :class="{ error: vRegister$.typeofuser.$errors.length }">
                                                        <select v-model="vRegister$.typeofuser.$model"
                                                            class="form-control nice-select wide" id="registration-type"
                                                            name="type" @blur="vRegister$.typeofuser.$touch" required>
                                                            <option selected disabled :value="null">Type d'usage
                                                            </option>
                                                            <option value="professional">Professionnel</option>
                                                            <option value="personal">Personnel</option>
                                                        </select>
                                                        <div class="input-errors"
                                                            v-for="error of vRegister$.typeofuser.$errors"
                                                            :key="error.$uid">
                                                            <span class="error-text">{{ error.$message }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row justify-content-center">
                                                    <div class="col-lg-6 col-sm-12 mt-1">
                                                        <div class="form-group"
                                                            :class="{ error: vRegister$.lastname.$errors.length }">
                                                            <input v-model="stateRegister.lastname" type="text"
                                                                id="lastname-register" name="lastname"
                                                                class="form-control" required placeholder="Nom"
                                                                @blur="vRegister$.lastname.$touch">
                                                            <div class="input-errors"
                                                                v-for="error of vRegister$.lastname.$errors"
                                                                :key="error.$uid">
                                                                <span class="error-text">{{ error.$message }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-sm-12 mt-1">
                                                        <div class="form-group"
                                                            :class="{ error: vRegister$.firstname.$errors.length }">
                                                            <input v-model="stateRegister.firstname" type="text"
                                                                id="firstname-register" name="firstname"
                                                                class="form-control" required placeholder="Prénom"
                                                                @blur="vRegister$.firstname.$touch">
                                                            <div class="input-errors"
                                                                v-for="error of vRegister$.firstname.$errors"
                                                                :key="error.$uid">
                                                                <span class="error-text">{{ error.$message }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row justify-content-center">
                                                    <div class="col-12 mt-1">
                                                        <div class="form-group" id="email-form-register"
                                                            :class="{ error: vRegister$.email.$errors.length }">
                                                            <input v-model="stateRegister.email" type="email"
                                                                id="email-register" name="email" class="form-control"
                                                                required placeholder="Adresse mail"
                                                                @blur="vRegister$.email.$touch">
                                                            <div class="input-errors"
                                                                v-for="error of vRegister$.email.$errors"
                                                                :key="error.$uid">
                                                                <span class="error-text">{{ error.$message }}</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-12 mt-1">
                                                        <div class="form-group" id="date-form-register"
                                                            :class="{ error: vRegister$.dateOfBirth.$errors.length }">
                                                            <input v-model="stateRegister.dateOfBirth" type="date"
                                                                id="date-register" name="date" class="form-control"
                                                                required placeholder="Date de naissance"
                                                                @blur="vRegister$.dateOfBirth.$touch">
                                                            <div class="input-errors"
                                                                v-for="error of vRegister$.dateOfBirth.$errors"
                                                                :key="error.$uid">
                                                                <span class="error-text">{{ error.$message }}</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-12 mt-1">
                                                        <div class="form-group"
                                                            :class="{ error: vRegister$.phone.$errors.length }">
                                                            <input v-model="stateRegister.phone" type="text"
                                                                id="phone-register" name="phone" class="form-control"
                                                                required placeholder="Téléphone"
                                                                @blur="vRegister$.phone.$touch">
                                                            <div class="input-errors"
                                                                v-for="error of vRegister$.phone.$errors"
                                                                :key="error.$uid">
                                                                <span class="error-text">{{ error.$message }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="stateRegister.typeofuser === 'professional'"
                                                    class="row justify-content-center">
                                                    <div class="col-lg-6 col-sm-12 mt-1">
                                                        <div class="form-group"
                                                            :class="{ error: vRegister$.companyName.$errors.length }">
                                                            <input v-model="stateRegister.companyName" type="text"
                                                                id="companyName-register" name="companyName"
                                                                class="form-control" placeholder="Raison sociale"
                                                                @blur="vRegister$.companyName.$touch">
                                                            <div class="input-errors"
                                                                v-for="error of vRegister$.companyName.$errors"
                                                                :key="error.$uid">
                                                                <span class="error-text">{{ error.$message }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-sm-12 mt-1">
                                                        <div class="form-group"
                                                            :class="{ error: vRegister$.siret.$errors.length }">
                                                            <input v-model="stateRegister.siret" type="text"
                                                                id="siret-register" name="siret" class="form-control"
                                                                placeholder="Numéro SIRET"
                                                                @blur="vRegister$.siret.$touch">
                                                            <div class="input-errors"
                                                                v-for="error of vRegister$.siret.$errors"
                                                                :key="error.$uid">
                                                                <span class="error-text">{{ error.$message }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row justify-content-center">
                                                    <div class="col-12 mt-1">
                                                        <div class="form-group"
                                                            :class="{ error: vRegister$.password.$errors.length }">
                                                            <input v-model="stateRegister.password" type="password"
                                                                id="password-register" name="password"
                                                                class="form-control" placeholder="Mot de passe" required
                                                                @blur="vRegister$.password.$touch">
                                                            <div class="input-errors"
                                                                v-for="error of vRegister$.password.$errors"
                                                                :key="error.$uid">
                                                                <span class="error-text">{{ error.$message }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row justify-content-center">
                                                    <div class="col-12 mt-1">
                                                        <div class="form-group"
                                                            :class="{ error: vRegister$.confirm_password.$errors.length }">
                                                            <input v-model="stateRegister.confirm_password"
                                                                type="password" id="confirm-password-register"
                                                                name="password" class="form-control"
                                                                placeholder="Confirmation du mot de passe" required
                                                                @blur="vRegister$.confirm_password.$touch">
                                                            <div class="input-errors"
                                                                v-for="error of vRegister$.confirm_password.$errors"
                                                                :key="error.$uid">
                                                                <span class="error-text">{{ error.$message }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row justify-content-center">
                                                    <div class="col-12 mt-1">
                                                        <div class="form-group">
                                                            <div class="form-check"
                                                                :class="{ error: vRegister$.agree.$errors.length }">
                                                                <label id="agree-register-label"
                                                                    class="form-check-label">
                                                                    <input v-model="vRegister$.agree.$model"
                                                                        type="checkbox" class="form-check-input"
                                                                        name="agree-register" id="agree-register"
                                                                        value="checkedValue" required
                                                                        @change="vRegister$.agree.$touch">
                                                                    J'ai lu et j'accepte <a href="http://"
                                                                        target="_blank" rel="noopener noreferrer">les
                                                                        conditions
                                                                        générales</a>
                                                                </label>
                                                            </div>
                                                            <div class="input-errors"
                                                                v-for="error of vRegister$.agree.$errors"
                                                                :key="error.$uid">
                                                                <span class="error-text">{{ error.$message }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row justify-content-center">
                                                    <div class="col-12 text-center">
                                                        <button type="button" @click="register"
                                                            class="btn form-btn btn-submit login-register-submit mt-2"
                                                            id="btn-register">
                                                            <div v-if="loading"
                                                                class="spinner-border spinner-border-sm text-white"
                                                                :class="{ 'hide': !loading }" role="status">
                                                                <span class="sr-only"></span>
                                                            </div>
                                                            <span v-else
                                                                id="text-register color-white">M'inscrire</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row hide" id="confirmation-register">
                            <div class="col-12">
                                <div v-if="status" class="confirmation">
                                    <img src="images/icons/confirmation-register.svg" alt="Inscription réussie">
                                    <span class="text-confirmation">Merci pour votre inscription ! Veuillez
                                        valider votre
                                        email
                                        afin d’accéder aux fonctionnalités de Hcenter</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { reactive } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, email, helpers, requiredIf } from '@vuelidate/validators'

export default {
    name: 'AuthView',
    setup() {

        const stateRegister = reactive({
            typeofuser: '',
            lastname: '',
            firstname: '',
            dateOfBirth: '',
            email: '',
            password: '',
            confirm_password: '',
            phone: '',
            companyName: '',
            siret: '',
            agree: null,
        })

        const rulesRegister = {
            typeofuser: {
                required: helpers.withMessage('Ce champ est requis', required),
            },
            lastname: {
                required: helpers.withMessage('Ce champ est requis', required),
            },
            firstname: {
                required: helpers.withMessage('Ce champ est requis', required),
            },
            email: {
                required: helpers.withMessage('Veuillez renseigner votre adresse mail', required),
                email: helpers.withMessage('Veuillez renseigner une adresse mail valide', email),
                //TODO: isUnique: helpers.withAsync(isEmailTaken)
            },
            dateOfBirth: {
                required: helpers.withMessage('Ce champ est requis', required),
                minValue: helpers.withMessage('Vous devez avoir au moins 18 ans pour vous inscrire', (value) => {
                    return new Date(value) <= new Date().setFullYear(new Date().getFullYear() - 18)
                }),
            },
            password: {
                required: helpers.withMessage('Veuillez renseigner votre mot de passe', required),
                password: helpers.withMessage('Votre mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule et un chiffre', (value) => {
                    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(value)
                }),
            },
            confirm_password: {
                required: helpers.withMessage('Veuillez confirmer votre mot de passe', required),
                sameAsPassword: helpers.withMessage('Les mots de passe ne correspondent pas', (value) => {
                    return value === stateRegister.password
                }),
            },
            phone: {
                required: helpers.withMessage('Veuillez renseigner votre numéro de téléphone', required),
                phone: helpers.withMessage('Veuillez renseigner un numéro de téléphone valide', (value) => {
                    return /^(0|\+33)[1-9]([-. ]?[0-9]{2}){4}$/.test(value)
                }),
            },
            companyName: {
                requiredIf: helpers.withMessage('Veuillez renseigner le nom de votre entreprise', requiredIf.apply(this, [() => {
                    return stateRegister.typeofuser === 'professional'
                }])),
            },
            siret: {
                requiredIf: helpers.withMessage('Veuillez renseigner le numéro de SIRET de votre entreprise', () => {
                    return stateRegister.typeofuser === 'professional'
                }),
                siret: helpers.withMessage('Veuillez renseigner un numéro de SIRET valide', (value) => {
                    return /^[0-9]{14}$/.test(value)
                }),
            },
            agree: {
                required: helpers.withMessage('Veuillez accepter les conditions générales', required),
            }
        }

        const stateLogin = reactive({
            email: '',
            password: '',
        })

        const rulesLogin = {
            email: {
                required: helpers.withMessage('Veuillez renseigner votre adresse mail', required),
                email: helpers.withMessage('Veuillez renseigner une adresse mail valide', email),
            },
            password: {
                required: helpers.withMessage('Veuillez renseigner votre mot de passe', required),
            },
        }

        const vRegister$ = useVuelidate(rulesRegister, stateRegister)

        const vLogin$ = useVuelidate(rulesLogin, stateLogin)

        return { stateRegister, vRegister$, stateLogin, vLogin$ }
    },
    data: function () {
        return {
            registrationType: '',
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            companyName: '',
            dateOfBirth: '',
            siret: '',
            password: '',
            confirm_password: '',
            agree: null,
            remember_me: false,
            loading: false,
            passwordFieldType: 'password',
            showPassword: false,
            status: {
                status: false,
                message: 'Votre addresse email ou votre mot de passe est incorrect',
            }
        }
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
    },
    mounted() {
        document.title = 'Déclic center - Connexion';
    },
    created() {
        if (this.loggedIn) {
            this.$router.push({ name: 'home' });
        }
    },
    methods: {
        async register() {
            const isFormCorrect = await this.vRegister$.$validate()

            if (!isFormCorrect)
                return

            // TODO: implement this
            // const isEmailTaken = (value) => fetch(`/api/unique/${value}`).then(r => r.json()) // check the email in the server
            let data = {
                firstname: this.stateRegister.firstname,
                lastname: this.stateRegister.lastname,
                email: this.stateRegister.email,
                dateOfBirth: this.stateRegister.dateOfBirth,
                phone: this.stateRegister.phone,
                companyName: this.stateRegister.companyName,
                siret: this.stateRegister.siret,
                password: this.stateRegister.password,
                confirm_password: this.stateRegister.confirm_password,
                isPro: this.stateRegister.typeofuser === 'professional' ? true : false,
                agree: this.stateRegister.agree,
            }
            this.loading = true;

            this.$store.dispatch("auth/register", data).then(
                () => {
                    this.$refs.loginToggle.click();
                    this.loading = false;
                    this.status.status = true;
                    this.status.message = "Votre compte a bien été créé, vous pouvez vous connecter";
                },
                (error) => {
                    this.loading = false;
                    this.status.status = true;
                    this.status.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        async login() {
            const isFormCorrect = await this.vLogin$.$validate()

            if (!isFormCorrect)
                return

            this.loading = true;
            let data = {
                email: this.stateLogin.email,
                password: this.stateLogin.password,
                remember_me: this.remember_me,
            }
            this.$store.dispatch("auth/login", data).then(
                () => {
                    this.$router.back();
                },
                (error) => {
                    this.loading = false;
                    this.status.status = true;
                    this.status.status = this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );

        },
        tabNav(tab) {
            this.registrationType = null;
            this.status.message = '';
            this.status.status = false;
            document.title = 'Déclic center - ' + tab;
        },

        passwordVisibility() {
            if (this.passwordFieldType === 'password')
                this.passwordFieldType = 'text';
            else
                this.passwordFieldType = 'password';
            this.showPassword = !this.showPassword;
        },
    },
}
</script>
<template>

	<section id="gallery" class="first-section">
		<div class="container">
			<h1 class="title-gallery">Galerie photo</h1>
			<div class="row justify-content-center">
				<GaleryOffice :photo="photo" v-for="photo in photos " :key="photo.id" />
			</div>
		</div>
		<GalleryModal :photo="photo" v-for="photo in photos " :key="photo.id" />
	</section>
</template>

<script>
import GaleryOffice from '@/components/GaleryOffice.vue'
import GalleryModal from '@/components/GalleryModal.vue'
import GaleryService from '@/services/galery.js'
export default {
	data() {
		return {
			photos: [],
		};
	}
	,
	components: {
		GaleryOffice,
		GalleryModal
	},
	mounted() {
		this.getPhotos();
	},
	methods: {
		getPhotos() {
			GaleryService.getGalery()
				.then(response => {
					this.photos = response.data
				})
				.catch(error => {
					console.log(error)
				})
		},
	}

}
</script>
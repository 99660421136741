<template>
    <div class="item-staff">
        <div class="container-img">
            <img :src="'https://www.centreo-beta.fr/images/job/'+ datajob.image " alt="">
            
        </div>
        <div class="container-text">
            <h3>{{ datajob.title }}</h3>
            <p>{{ datajob.description }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name:"centerJob",
    props: {
        datajob: Object
    }
}
</script>

<style>

</style>
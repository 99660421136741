import axios from 'axios';

class DomiciliationService {
    domiciliation(data) {
        return axios
            .post('/centreo/domiciliation', {
                idOwner: data.idOwner,
                idTeam : data.idTeam,
                type : data.type,
                activity : data.activity,
                forme : data.forme,
                description : data.description,
                subscription : data.subscription,
                hasRedirection : data.hasRedirection,
                hotlinePrice: data.hotlinePrice,
                maxCall : data.maxCall,
                domiciliationPrice : data.domiciliationPrice,
                proScanPrice : data.proScanPrice,
                totalPrice : data.totalPrice,
                totalPriceExTax: data.totalPriceExTax,
                hotlinePriceSlice: data.hotlinePriceSlice
            })
            .then(() => {

            })
            .catch((e) => {
                this.errors.push(e)
            })
          
    }
    storeDom(type, dom, price, scan,form,scanPrice,total,priceTax,totalPriceTax,priceHotline) {

        localStorage.setItem('dom', JSON.stringify({
            "type": type,
            "price": price,
            "dom": dom,
            "scan": scan,
            "form":form,
            "scanPrice":scanPrice,
            "total": total,
            "priceTax": priceTax,
            "totalPriceTax":totalPriceTax,
            "priceHotline" : priceHotline
        }));
    }
    storeHotline(duration,dom,price,total,priceTax,totalPriceTax ) {

        localStorage.setItem('dom', JSON.stringify({
            "duration": duration,
            "dom": dom,
            "price": price,
            "total": total,
            "priceTax":priceTax,
            "totalPriceTax" :totalPriceTax 
            
        }));
    }

    getDomiciliations() {
        return axios.get('/centreo/domiciliation/2', {
            // team_id: 2
        });
    }
    getDomiciliationOffers() {
        return axios.get('/centreo/domiciliation-offer/2', {

        });
    }
    getHotlineOffers() {
        return axios.get('/centreo/hotline-offer/2', {

        });
    }
    getTax() {
        return axios.get('/centreo/team-info/2', {
            // team_id: 2
        });
    }

}

export default new DomiciliationService();

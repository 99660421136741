<template>
  <div class="row component-menu">
    <div class="menu d-none d-md-block d-lg-block d-xl-block">
      <div class="menu-item">
        <a href="#" class="btn btn-account">Espaces</a>
      </div>
      <div class="menu-item">
        <a href="#" class="btn btn-account">Domiciliation et permanence tél.</a>
      </div>
      <div class="menu-item">
        <a href="#" class="btn btn-account">Consommables</a>
      </div>
      <div class="menu-item">
        <a href="#" class="btn btn-account active">Factures</a>
      </div>
      <div class="menu-item">
        <a href="#" class="btn btn-account active">Profil</a>
      </div>
      <div class="menu-item logout">
        <a href="#" class="btn btn-logout">
          <img src="#" alt="logout" class="logout">
        </a>
      </div>
    </div>
    <!-- Responsive -->
    <div class="menu-responsive d-block d-md-none d-lg-none d-xl-none">

        <div class="dropdown">
          <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            Profil
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li><a href="#" class="btn-account">Mes réservations</a></li>
            <li><a href="#" class="btn-account">Mes factures</a></li>
            <li><a href="#" class="btn-account">Mes justificatifs</a></li>
            <li><a href="#" class="btn-account active">Mon profil</a></li>
          </ul>
        </div>
      <div class="dropdown d-flex menu-secondaire">
        <button class="btn-edit">
          edit
        </button>
        <button class="btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
          Informations personnelles
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li><a href="#" class="btn-account">Informations personnelles</a></li>
          <li><a href="#" class="btn-account">Informations professionnelles</a></li>
          <li><a href="#" class="btn-account">Informations bancaires</a></li>
          <li><a href="#" class="btn-account active">Documents justificatifs</a></li>
        </ul>
      </div>




    </div>
  </div>
</template>
<script>
export default {
  name: 'AccountMenuComponent'
}
</script>
<style>

</style>
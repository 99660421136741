<template>
    <section class="first-section">
        <div class="row justify-content-center">
            <div class="col-4">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Réinitialisation de mot de passe</h5>
                        <form @submit.prevent="forgotPassword">
                            <div class="row justify-content-center">
                                <div class="col-12">
                                    <div class="form-group">
                                        <input v-model="email" type="email" name="_username" class="form-control"
                                            required placeholder="Adresse mail">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <button id="submit" class="btn" type="submit">Réinitialiser</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "ForgotPasswordView",
    data() {
        return {
            email: "",
            message: "",
        };
    },
    mounted() {
        document.title = "Déclic center | Réinitialisation de mot de passe";
    },
    methods: {
        forgotPassword() {
            this.$store.dispatch("auth/forgotPassword", this.email).then(() => {
                this.message = "Un email de réinitialisation de mot de passe vous a été envoyé.";
            }, (error) => {
                this.message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
            });
        },
    }
}
</script>

<style lang="scss">
.first-section {
    height: 54%;
}

#submit {
    background-color: #2f3246;
    width: 200px;
    height: 40px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    color: #fff;
}
</style>

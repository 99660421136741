<template>
    <section id="domiciliation" class="first-section">
        <div class="scope">
            <div class="row">
                <h1 class="col-11 col-md-11 col-xl-8  mx-auto">Souscrire en ligne à la domiciliation</h1>
                <First-section />

            </div>

            <ul class="nav nav-pills my-4 justify-content-center" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                    <a @click="setDuration('trimestrielle')" data-duration="0" class="nav-link active"
                        id="pills-home-tab" data-bs-toggle="pill" href="#pills-standard-quarterly" role="tab"
                        aria-controls="pills-home" aria-selected="true">Trimestrielle</a>
                </li>
                <li class="nav-item" role="presentation">
                    <a @click="setDuration('annuelle')" data-duration="1" class="nav-link" id="pills-profile-tab"
                        data-bs-toggle="pill" href="#pills-standard-annual" role="tab" aria-controls="pills-profile"
                        aria-selected="false">Annuelle</a>
                </li>
            </ul>

            <section id="offer">


                <div class="infos">
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-standard-quarterly" role="tabpanel"
                            aria-labelledby="pills-standard-quarterly-tab">
                            <div class="contain-card-offer">
                                <div class="contain-form">
                                    <div class="card-offer card-offer-purpledark">
                                        <div class="content-title">
                                            <h3>DOMICILIATION</h3>
                                        </div>
                                        <div class="domiciliation-info">
                                            <p class="title-offer"><input type="radio"
                                                    class="domiciliation-price checkbox-domiciliation priceDom "
                                                    name="domiciliation-price" @click="setDom('Domiciliation Trimestrielle', 45,'dom')" v-model="chekDomOneMonth"
                                                    data-v-model="domiciliationprice" data-price="45"
                                                    value="45/false/Domiciliation Trimestrielle/month" required>
                                                Domiciliation Trimestrielle</p>

                                            <p class="price">À partir de <span
                                                    class="info-price info-pricepurpledark">45 € HT</span> /mois</p>
                                            <p class="info-duration text-center">Engagement de 3 mois minimum</p>

                                            <hr>
                                            <p class="title-offer"><input type="radio" class="domiciliation-price checkbox-domiciliation priceDom " name="domiciliation-price" @click="setDom('Domiciliation Trimestrielle avec Réexpédition', 60,'dom')"
                                                    data-v-model="domiciliationprice" data-price="60" v-model="chekDomTwoMonth"
                                                    value="60/true/Domiciliation Trimestrielle avec Réexpédition/month"
                                                    required> Domiciliation Trimestrielle avec Réexpédition</p>
                                            <p class="price">À partir de <span
                                                    class="info-price info-pricepurpledark">60 € HT</span> /mois</p>
                                            <p class="info-duration text-center">Engagement de 3 mois minimum</p>
                                        </div>
                                        <div class="container-option-scan position-relative container-option-scan-dom">
                                            <div class="card">
                                                <h5 class="mb-0">
                                                    <div class="btn-collapse">
                                                        <input type="checkbox" name="proScan" @click="setScan($event,120,'dom')" v-model="checkScanDom"
                                                            data-v-model="scan" value="120" 
                                                            class="radio-scan-pro scan-radio scanProDom"> Option scan pro <span class="scan-pro"><span>120 €</span> HT /mois</span>
                                                        <input name="noProScan" value="120" data-v-model="scan" hidden>
                                                        <img data-bs-toggle="collapse"
                                                            data-bs-target="#collapseDimiciliation"
                                                            aria-expanded="true"
                                                            aria-controls="collapseDimiciliationTri"
                                                            src="images/icons/inter.png" class="btn-option-dom">

                                                    </div>
                                                </h5>
                                            </div>
                                            <div id="collapseDimiciliation" class="collapse collapse-scan"
                                                aria-labelledby="headingOne" data-parent="#accordion">
                                                <div class="card-body">
                                                    L’option scan pro permet de ...........<br>
                                                    sit amet, consectetur adipiscing elit ut aliquam, purus sit amet
                                                    luctus venenatis elit ut aliquam, purus sit amet
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="total-price">
                                        <p class="text-center">Prix total HT <span
                                                class="price-total span-purpledark ">{{ domHt }} €</span> /mois</p>
                                    </div>
                                    <div class="contain-btn">
                                        <input type="hidden" name="domiciliation" value="on">
                                        <input type="hidden" name="duration" value="quarterly">
                                        <button type="submit" class="btn btn-submit" :disabled="!chekDomOneMonth && !chekDomTwoMonth" @click="storeDom">
                                            <span class="nav-link ps-0 pb-0 mb-0"> Souscrire en ligne</span>
                                        </button>
                                    </div>
                                </div>
                                
                                <div class="contain-form">
                                    <div class="card-offer card-offer-pink">
                                        <div class="content-title orange">
                                            <h3>PERMANENCE TÉLÉPHONIQUE</h3>
                                        </div>

                                        <div class="telephone-permanence">
                                            <span> À partir de </span>
                                            <p class="basic-offer"><span class="basic-call">4 appels</span>pour<span class="basic-call-price">12 HT</span> <span class="basic-month">/mois</span></p>

                                            <span class="title-slice"> Au-delà de 50 appels</span>

                                                                            
                                            <p class="content-slice">
                                                De <span class="slice-call">10</span> à <span class="slice-call">10</span> appels <span class="slice-price">20 € HT <span>/appel</span></span>
                                            </p>
                                            <p class="content-slice">
                                                De <span class="slice-call">30</span> à <span class="slice-call">38</span> appels <span class="slice-price">30€ HT <span>/appel</span></span>
                                            </p>
                                            <p class="content-slice">
                                                De <span class="slice-call">40</span> à <span class="slice-call">48</span> appels <span class="slice-price">40 € HT <span>/appel</span></span>
                                            </p>
                                                                        
                                            <input type="hidden" name="hotline-price" value="56">
                                            <input name="noProScan" value="0" type="hidden">
                                        </div>
                                        <p class="info-hotline">Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat eaque incidunt vel voluptatum impedit? Ullam?</p>
                                    </div>
                                        <div class="space-btn">
                                        </div>
                                    <div class="contain-btn">
                                        <input type="hidden" name="duration" value="quarterly">
                                        <input type="hidden" name="hotline" value="on">
                                        <button type="submit" class="btn btn-submit"   @click="storeHot">
                                            <span class="nav-link ps-0 pb-0 mb-0"> Souscrire en ligne</span>
                                                                        
                                        </button>
                                    </div>
                                </div>
                                
                                <div class="contain-form">
                                        <div class="card-offer card-offer-purple">
                                            <div class="content-title yellow">
                                                <h3>DOMICILIATION</h3>
                                                <span>ET PERMANENCE TÉLÉPHONIQUE</span>
                                            </div>
                                            <div class="domiciliation-info">
                                            <p class="title-offer mt-3">Domiciliation trimestrielle</p>
                                            <p>
                                                <a class="btn-domiciliation-info btn-dom-tri" data-bs-toggle="collapse" href="#collapseDomiciliationPlusTri" role="button" aria-expanded="false" aria-controls="collapseDomiciliationPlusTri">
                                                    <span class="place-discount"></span>
                                                    <span class="shadow-triangle-top-left "></span>
                                                    <span class="discount-left">
                                                    <span class="save-monthly top">
                                                        Économisez 
                                                    </span>
                                                    <span class="amount">
                                                        10
                                                    </span>
                                                    <span class="euro">
                                                        €
                                                        <span class="no-tax">HT</span>
                                                    </span>
                                                    <span class="save-monthly bottom"> / mois</span></span>
                                                    <span class="shadow-triangle-bottom-left "></span>
                                                    <span class="from-text">À partir de </span>
                                                    <span class="info-price">&nbsp; 23 € HT&nbsp;</span> /mois
                                                    <button class="slick-next slick-arrow arrow-down btnTri" aria-label="Next" type="button" style=""></button>
                                                </a>
                                            </p>
                                            <div class="collapse collapse-domiciliation" id="collapseDomiciliationPlusTri">
                                                <div class="card-body">
                                                    <p class="title-offer pl-0"><input class="domTrimestriel checkbox-domiciliation priceDomHotline" v-model="chekDomTelOneMonth" type="radio" @click="setDom('Domiciliation trimestrielle et permanence téléphonique', 45,'domHotline')" name="domiciliation-price" data-hotline="45" value="45/false/Domiciliation Trimestrielle/month"  required> Domiciliation Trimestrielle</p>
                                                    <p class="price">À partir de <span class="info-price">45</span>  € HT /mois</p>
                                                    <hr>
                                                    <p class="title-offer pl-0"><input class="domTrimestriel checkbox-domiciliation priceDomHotline"  v-model="chekDomTelTwoMonth" type="radio"  @click="setDom('Domiciliation trimestrielle et permanence téléphonique', 34,'domHotline')" name="domiciliation-price" data-hotline="46" value="46/true/Domiciliation Trimestrielle avec Réexpédition/month"  required> Domiciliation Trimestrielle avec Réexpédition</p>
                                                    <p class="price">À partir de <span class="info-price">34</span> € HT /mois</p>
                                                </div>
                                            </div>
                                                            
                                                                            
                                            <hr>
                                            <p class="title-offer">Permanence téléphonique</p>
                                            <p>
                                                <a class="btn-domiciliation-info btn-perm-tel" data-bs-toggle="collapse" href="#collapseHotlinePlusTri" role="button" aria-expanded="false" aria-controls="collapseHotlinePlusTri">
                                                    30 appels <span class="info-price">&nbsp; {{this.priceHotline}}€ HT&nbsp;</span>
                                                    <button class="slick-next slick-arrow arrow-down btnPerm" aria-label="Next" type="button" style=""></button>
                                                </a>
                                            </p>
                                            <div class="collapse collapse-domiciliation" id="collapseHotlinePlusTri">
                                                <div class="card-body">
                                                    <p class="title-offer pl-0">23 appels <span class="info-price text-right w-50">45€ HT</span><span> /appel</span></p>
                                                                                    
                                                    <hr>
                                                                                
                                                    <input type="hidden" name="hotline-price" value="23">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="container-option-scan position-relative container-option-scan-perm">
                                            <div class="card">
                                                <h5 class="mb-0">
                                                    <div class="btn-collapse yellow" >
                                                        <input id="scanPro" type="checkbox" name="proScan" v-model="checkScan" @click="setScan($event,120,'domHotline')" data-proscanhotline="30" value="45" class="radio-scan-pro scan-radio scanPro"> Option scan pro <span class="scan-pro"><span>45 €</span> HT /mois</span>
                                                        <input name="noProScan" value="45" hidden>
                                                        <img type="button" data-bs-toggle="collapse" class="btn-option down" data-bs-target="#collapseScanProTri" aria-expanded="true" aria-controls="collapseScanProTri" src="images/icons/inter.png"  >
                                                    </div>
                                                </h5>
                                            </div>
                                            <div id="collapseScanProTri" class="collapse collapse-scan" aria-labelledby="headingOne" data-parent="#accordion">
                                                <div class="card-body">
                                                    L’option scan pro permet de ...........<br>
                                                    sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis elit ut aliquam, purus sit amet 
                                                </div>
                                            </div>
                                                                            
                                                                            
                                        </div>
                                                                        
                                        </div>
                                        <div class="total-price">
                                            <p class="text-center">Prix total HT <span class="price-totals span-pink" id="price-domPerm-tri">{{ domHot }} €</span> /mois</p>
                                        </div>
                                        <div class="contain-btn">
                                            <input type="hidden" name="domiciliation-plus" value="on">
                                            <input type="hidden" name="duration" value="quarterly">
                                            <button type="submit" class="btn btn-submit" :disabled="!chekDomTelOneMonth && !chekDomTelTwoMonth " @click="storeDom">
                                                <span class="nav-link ps-0 pb-0 mb-0"> Souscrire en ligne</span>
                                            </button>
                                        </div>
                                </div>
                                    
                                
                            </div>
                        </div>
                        <div class="tab-pane fade" id="pills-standard-annual" role="tabpanel"
                            aria-labelledby="pills-standard-annual-tab">
                            <div class="contain-card-offer">
                                <div class="contain-form">
                                    <div class="card-offer card-offer-purpledark">
                                        <div class="content-title">
                                            <h3>DOMICILIATION</h3>
                                        </div>
                                        <div class="domiciliation-info">
                                            <p class="title-offer"><input type="radio"
                                                    class="domiciliation-price checkbox-domiciliation priceDom "
                                                    name="domiciliation-price" @click="setDom('Domiciliation annuelle', 45,'dom')"
                                                    data-v-model="domiciliationprice" data-price="45" v-model="chekDomOneYear"
                                                    value="45/false/Domiciliation Trimestrielle/month" required>  Domiciliation Trimestrielle</p>

                                            <p class="price">À partir de <span
                                                    class="info-price info-pricepurpledark">45 € HT</span> /mois</p>
                                            <p class="info-duration text-center">Engagement de 3 mois minimum</p>

                                            <hr>
                                            <p class="title-offer"><input type="radio"
                                                    class="domiciliation-price checkbox-domiciliation priceDom "
                                                    name="domiciliation-price" @click="setDom('Domiciliation annuelle avec Réexpédition', 60,'dom')"   v-model="chekDomTwoYear"
                                                    data-v-model="domiciliationprice" data-price="60"
                                                    value="60/true/Domiciliation Trimestrielle avec Réexpédition/month"
                                                    required> Domiciliation Trimestrielle avec Réexpédition</p>
                                            <p class="price">À partir de <span
                                                    class="info-price info-pricepurpledark">60 € HT</span> /mois</p>
                                            <p class="info-duration text-center">Engagement de 3 mois minimum</p>
                                        </div>
                                        <div class="container-option-scan position-relative container-option-scan-dom">
                                            <div class="card">
                                                <h5 class="mb-0">
                                                    <div class="btn-collapse">
                                                        <input type="checkbox" name="proScan" @click="setScan($event,120,'dom')" v-model="checkScanDom"
                                                            data-v-model="scan" value="120" data-proscan="120"
                                                            class="radio-scan-pro scan-radio scanProDom"> Option scan pro <span class="scan-pro"><span>120 €</span> HT /mois</span>
                                                        <input name="noProScan" value="120" data-v-model="scan" hidden>
                                                        <img data-bs-toggle="collapse"
                                                            data-bs-target="#collapseDimiciliationTri"
                                                            aria-expanded="true"
                                                            aria-controls="collapseDimiciliationTri"
                                                            src="images/icons/inter.png" class="btn-option-dom">

                                                    </div>
                                                </h5>
                                            </div>
                                            <div id="collapseDimiciliationTri" class="collapse collapse-scan"
                                                aria-labelledby="headingOne" data-parent="#accordion">
                                                <div class="card-body">
                                                    L’option scan pro permet de ...........<br>
                                                    sit amet, consectetur adipiscing elit ut aliquam, purus sit amet
                                                    luctus venenatis elit ut aliquam, purus sit amet
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="total-price">
                                        <p class="text-center">Prix total HT <span
                                                class="price-total span-purpledark ">{{ domHt }} €</span> /mois</p>
                                    </div>
                                    <div class="contain-btn">
                                        <input type="hidden" name="domiciliation" value="on">
                                        <input type="hidden" name="duration" value="quarterly">
                                        <button type="submit" class="btn btn-submit" :disabled="!chekDomOneYear && !chekDomTwoYear" @click="storeDom">
                                            <span class="nav-link ps-0 pb-0 mb-0"> Souscrire en ligne</span>
                                          
                                        </button>
                                    </div>
                                </div>
                                
                                <div class="contain-form">
                                    <div class="card-offer card-offer-pink">
                                        <div class="content-title orange">
                                            <h3>PERMANENCE TÉLÉPHONIQUE</h3>
                                        </div>

                                        <div class="telephone-permanence">
                                            <span> À partir de </span>
                                            <p class="basic-offer"><span class="basic-call">4 appels</span>pour<span class="basic-call-price">12 HT</span> <span class="basic-month">/mois</span></p>

                                            <span class="title-slice"> Au-delà de 50 appels</span>

                                                                            
                                            <p class="content-slice">
                                                De <span class="slice-call">10</span> à <span class="slice-call">10</span> appels <span class="slice-price">20 € HT <span>/appel</span></span>
                                            </p>
                                            <p class="content-slice">
                                                De <span class="slice-call">30</span> à <span class="slice-call">38</span> appels <span class="slice-price">30€ HT <span>/appel</span></span>
                                            </p>
                                            <p class="content-slice">
                                                De <span class="slice-call">40</span> à <span class="slice-call">48</span> appels <span class="slice-price">40 € HT <span>/appel</span></span>
                                            </p>
                                                                        
                                            <input type="hidden" name="hotline-price" value="56">
                                            <input name="noProScan" value="0" type="hidden">
                                        </div>
                                        <p class="info-hotline">Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat eaque incidunt vel voluptatum impedit? Ullam?</p>
                                    </div>
                                        <div class="space-btn">
                                        </div>
                                    <div class="contain-btn">
                                        <input type="hidden" name="duration" value="quarterly">
                                        <input type="hidden" name="hotline" value="on">
                                        <button type="submit" class="btn btn-submit"   @click="storeHot">
                                            <span class="nav-link ps-0 pb-0 mb-0"> Souscrire en ligne</span>
                                                                        
                                        </button>
                                    </div>
                                </div>

                                <div class="contain-form">
                                        <div class="card-offer card-offer-purple">
                                            <div class="content-title yellow">
                                                <h3>DOMICILIATION</h3>
                                                <span>ET PERMANENCE TÉLÉPHONIQUE</span>
                                            </div>
                                            <div class="domiciliation-info">
                                            <p class="title-offer mt-3">Domiciliation trimestrielle</p>
                                            <p>
                                                <a class="btn-domiciliation-info btn-dom-tri" data-bs-toggle="collapse" href="#collapseDomiciliationPlus" role="button" aria-expanded="false" aria-controls="collapseDomiciliationPlusTri">
                                                    <span class="place-discount"></span>
                                                    <span class="shadow-triangle-top-left "></span>
                                                    <span class="discount-left">
                                                    <span class="save-monthly top">
                                                        Économisez 
                                                    </span>
                                                    <span class="amount">
                                                        10
                                                    </span>
                                                    <span class="euro">
                                                        €
                                                        <span class="no-tax">HT</span>
                                                    </span>
                                                    <span class="save-monthly bottom"> / mois</span></span>
                                                    <span class="shadow-triangle-bottom-left "></span>
                                                    <span class="from-text">À partir de </span>
                                                    <span class="info-price">&nbsp; 23 € HT&nbsp;</span> /mois
                                                    <button class="slick-next slick-arrow arrow-down btnTri" aria-label="Next" type="button" style=""></button>
                                                </a>
                                            </p>
                                            <div class="collapse collapse-domiciliation" id="collapseDomiciliationPlus">
                                                <div class="card-body">
                                                    <p class="title-offer pl-0"><input class="domTrimestriel checkbox-domiciliation priceDomHotline" type="radio" v-model="chekDomTelOneYear"  @click="setDom('Domiciliation annuelle et permanence téléphonique', 45,'domHotline')" name="domiciliation-price" data-hotline="45" value="45/false/Domiciliation Trimestrielle/month"  required> Domiciliation Trimestrielle</p>
                                                    <p class="price">À partir de <span class="info-price">45</span>  € HT /mois</p>
                                                    <hr>
                                                    <p class="title-offer pl-0"><input class="domTrimestriel checkbox-domiciliation priceDomHotline" type="radio" v-model="chekDomTelTwoYear" @click="setDom('Domiciliation annuelle et permanence téléphonique', 34,'domHotline')" name="domiciliation-price" data-hotline="46" value="46/true/Domiciliation Trimestrielle avec Réexpédition/month"  required> Domiciliation Trimestrielle avec Réexpédition</p>
                                                    <p class="price">À partir de <span class="info-price">34</span> € HT /mois</p>
                                                </div>
                                            </div>
                                                            
                                                                            
                                            <hr>
                                            <p class="title-offer">Permanence téléphonique</p>
                                            <p>
                                                <a class="btn-domiciliation-info btn-perm-tel" data-bs-toggle="collapse" href="#collapseHotlinePlus" role="button" aria-expanded="false" aria-controls="collapseHotlinePlusTri">
                                                    30 appels <span class="info-price">&nbsp; {{this.priceHotline}}€ HT&nbsp;</span>
                                                    <button class="slick-next slick-arrow arrow-down btnPerm" aria-label="Next" type="button" style=""></button>
                                                </a>
                                            </p>
                                            <div class="collapse collapse-domiciliation" id="collapseHotlinePlus">
                                                <div class="card-body">
                                                    <p class="title-offer pl-0">23 appels <span class="info-price text-right w-50">45€ HT</span><span> /appel</span></p>
                                                                                    
                                                    <hr>
                                                                                
                                                    <input type="hidden" name="hotline-price" value="23">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="container-option-scan position-relative container-option-scan-perm">
                                            <div class="card">
                                                <h5 class="mb-0">
                                                    <div class="btn-collapse yellow" >
                                                        <input id="scanPro" type="checkbox" name="proScan" v-model="checkScan" @click="setScan($event,120,'domHotline')"   data-proscanhotline="30" value="45" class="radio-scan-pro scan-radio scanPro"> Option scan pro <span class="scan-pro"><span>45 €</span> HT /mois</span>
                                                        <input name="noProScan" value="45" hidden>
                                                        <img type="button" data-bs-toggle="collapse" class="btn-option down" data-bs-target="#collapseScanPro" aria-expanded="true" aria-controls="collapseScanProTri" src="images/icons/inter.png"  >
                                                    </div>
                                                </h5>
                                            </div>
                                            <div id="collapseScanPro" class="collapse collapse-scan" aria-labelledby="headingOne" data-parent="#accordion">
                                                <div class="card-body">
                                                    L’option scan pro permet de ...........<br>
                                                    sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis elit ut aliquam, purus sit amet 
                                                </div>
                                            </div>
                                                                            
                                                                            
                                        </div>
                                                                        
                                        </div>
                                        <div class="total-price">
                                            <p class="text-center">Prix total HT <span class="price-totals span-pink" id="price-domPerm-tri">{{ domHot }} €</span> /mois</p>
                                        </div>
                                        <div class="contain-btn">
                                            <input type="hidden" name="domiciliation-plus" value="on">
                                            <input type="hidden" name="duration" value="quarterly">
                                            
                                            <button type="submit" class="btn btn-submit"  :disabled="!chekDomTelOneYear && !chekDomTelTwoYear" @click="storeDom">
                                                <span class="nav-link ps-0 pb-0 mb-0"> Souscrire en ligne</span>
                                               
                                            </button>
                                        </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </section>


</template>

<script>
import FirstSection from "../components/domiciliation/FirstSection.vue"
import DomiciliationService from '../services/domiciliation.js'

export default {
    name: 'DomiciliationView',
    components: {
        FirstSection,
     
    },
    data() {
        return {
            duration: 'Trimestrielle',
            dom: '',
            price: 0,
            scan: false,
            scanPrice: 0,
            domHt: 0,
            domHot: 0,
            type:'',
            form:'',
            checkScan:'',
            checkScanDom:"",
            chekDomOne:"",
            chekDomTwo:"",
            chekDomTelOneYear:'',
            chekDomTelTwoYear:'',
            chekDomTelOne:'',
            chekDomTelTwo:'',
            chekDomTelOneMonth:'',
            chekDomTelTwoMonth:'',
            chekDomOneYear:'',
            chekDomTwoYear:'',
            chekDomOneMonth:'',
            chekDomTwoMonth:'',
            total: 0,
            priceTax: 0,
            tax :0 ,
            totaltax: 0,
            totalPriceTax: 0,
            priceHotline: 0
        
            
        }
    },
    methods: {
        setDuration(duration) {
            this.duration = duration;
            this.domHt = 0;
            this.domHot = 0;
            this.scanPrice = 0 ;
             this.dom = "";
             this.form = "";
             this.checkScan="";
             this.checkScanDom="";
             this.chekDomTelOneYear='',
             this.chekDomTelTwoYear='',
             this.chekDomTelOneMonth='',
             this.chekDomTwoMonth='',
             this.chekDomOneYear='',
             this.chekDomTwoYear='',
             this.chekDomOneMonth='',
             this.chekDomTwoMonth=''
 
        },
     
        setDom(dom, price,type) {
            if(type=="dom"){
                this.form ="dom"
                this.dom = dom;
                this.price = price;
                this.domHt = this.price + this.scanPrice;
                this.total= this.domHt
                this.priceTax = this.total * this.totaltax
                this.totalPriceTax = this.priceTax  + this.total
               
            }else{
                this.form ="domHt"
                this.dom = dom;
                this.price = price;
                this.domHot = this.price + this.scanPrice + parseFloat(this.priceHotline) ;
                this.total= this.domHot 
                this.priceTax = this.total * this.totaltax 
                this.totalPriceTax = this.priceTax + this.total 
            }
            
        },
        setScan(event,scanPrice,type) {
            if(type=="dom"){
                this.scan = !this.scan;
                this.scanPrice = (event.target.checked) ? scanPrice : 0;
                this.domHt = this.price + this.scanPrice;
                this.total = this.domHt
                this.priceTax = this.total * this.totaltax
                this.totalPriceTax = this.priceTax + this.total
            }else{
                this.scan = !this.scan;
                this.scanPrice = (event.target.checked) ? scanPrice : 0;
                this.domHot = this.price + this.scanPrice + parseFloat(this.priceHotline);
                this.total = this.domHot 
                this.priceTax = this.total * this.totaltax 
                this.totalPriceTax = this.priceTax + this.total 
            }
            
        },
     
        storeDom(){
            DomiciliationService.storeDom(
                this.duration,
                this.dom,
                this.price,
                this.scan,
                this.form,
                this.scanPrice,
                this.total,
                this.priceTax,
                this.totalPriceTax,
                parseFloat(this.priceHotline),
                
                //console.log(this.duration, this.dom , this.price)
            )
            
            this.$router.push({ path: '/domiciliation/reservation' });
        },
        storeHot(){
            DomiciliationService.storeHotline(
                this.duration,
                this.dom ="permanence téléphonique",
                this.priceHotline,
                this.total= parseFloat(this.priceHotline),
                this.priceTax = this.total * this.totaltax ,
                this.totalPriceTax =  parseFloat(this.priceHotline) + this.priceTax
               
                
                
            )

            
            this.$router.push({ path: '/domiciliation/reservation' });
        },
        getTaxInfo(){
            DomiciliationService.getTax()
                .then(response => {
					this.tax = response.data;
                    this.totaltax = (this.tax/100);
				})
				.catch(e => {
					console.log(e);
			});
            
        },
        getHotlineOffer(){
            DomiciliationService.getHotlineOffers()
                .then(response => {
					this.priceHotline = response.data[0].price;
				})
				.catch(e => {
					console.log(e);
			});
            
        },
      
    },
    mounted() {
        this.getTaxInfo();
        this.getHotlineOffer();
        localStorage.removeItem('dom')
        document.title = 'Déclic Center | Domiciliation';

    }
    
}
</script>

<template>
  <div class="profile-bloc">
    <section class="menu-component">
      <AccountMenuComponent/>
    </section>
    <section id="bookings" class="account first-section">
      <div class="container">
        <div class="row mt-3">
          <form action="" method="post" id="payment-update">
          <h2 class="mb-3 ml-xl-3 d-none d-md-block d-lg-block d-xl-block">Informations personnelles <img class="btn-update" role="button" src="#" alt="edit">
            <button type="submit" class="checked-btn btn" hidden>Enregistrer les modifications<img src="#" alt="checked">
            </button>
          </h2>
          <div class="content">
            <div class="content-item profile">
              <div class="description">
                <div class="description-item">
                  <div>
                    <p class="info">Nom</p>
                    <input type="text" class="user-info" id="inputname" name="inputname" placeholder="Nom" value="" disabled>
                    <div class="underline"></div>
                  </div>
                  <div>
                    <p class="info">Prénom</p>
                    <input type="text" class="user-info" id="inputfirstname" name="inputfirstname" placeholder="Prénom" value="" disabled>
                    <div class="underline"></div>
                  </div>
                  <div>
                    <p class="info">Date de naissance</p>
                    <input type="text" class="user-info" id="inputdate" name="inputdate" placeholder="Date de naissance" value="" disabled>
                    <div class="underline"></div>
                  </div>
                  <div>
                    <p class="info">Téléphone</p>
                    <input type="text" class="user-info" id="inputphone" name="inputphone" placeholder="Téléfone" value="" disabled>
                    <div class="underline"></div>
                  </div>
                  <div>
                    <p class="info">Adresse email</p>
                    <input type="text" class="user-info" id="inputmail" name="inputmail" placeholder="Email" value="" disabled>
                    <div class="underline"></div>
                  </div>
                  <div>
                    <p class="info">Mot de passe</p>
                    <input type="text" class="user-info" id="inputpassword" name="inputpassword" placeholder="*****" value="" disabled>
                    <div class="underline"></div>
                  </div>
                  <div>
                    <p class="info">Adresse</p>
                    <input type="text" class="user-info" id="inputadresse" name="inputadresse" placeholder="Adresse" value="" disabled>
                    <div class="underline"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </form>
          <form action="" method="post" id="payment-update">
          <h2 class="mb-3 ml-xl-3 d-none d-md-block d-lg-block d-xl-block">Informations professionnelles <img class="btn-update" role="button" src="#" alt="edit">
            <button type="submit" class="checked-btn btn" hidden>Enregistrer les modifications<img src="#" alt="checked">
            </button>
          </h2>
          <div class="content">
            <div class="content-item profile">
              <div class="description">
                <div class="description-item">
                  <div>
                    <p class="info">Raison sociale</p>
                    <input type="text" class="user-info" id="raisson" name="raisson" placeholder="Raison sociale" value="" disabled>
                    <div class="underline"></div>
                  </div>
                  <div>
                    <p class="info">Numéro de siret</p>
                    <input type="text" class="user-info" id="siret" name="siret" placeholder="Numéro de siret" value="" disabled>
                    <div class="underline"></div>
                  </div>
                  <div>
                    <p class="info">Statut</p>
                    <input type="text" class="user-info" id="statut" name="statut" placeholder="Statut" value="" disabled>
                    <div class="underline"></div>
                  </div>
                  <div>
                    <p class="info">Adresse réexpédition</p>
                    <input type="text" class="user-info" id="reexpedition" name="reexpedition" placeholder="Adresse réexpédition" value="" disabled>
                    <div class="underline"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </form>
      <form action="" method="post" id="payment-update">
        <h2 class="mb-3 ml-xl-3 d-none d-md-block d-lg-block d-xl-block">Informations bancaires <img class="btn-update" role="button" src="images/icons/pen-edit.svg" alt="edit">
          <button type="submit" class="checked-btn btn" id="card-button" data-secret="" hidden>Enregistrer les
            modifications<img src="images/icons/checked-btn.svg" alt="checked"></button>
        </h2>
        <div class="content">
        <div class="h-auto content-item profile d-flex flex-column" id="info">
          <div id="info-text" class="w-100">
            <p class="txt-orange">Simplifiez-vous la vie : passez au paiement par carte bancaire !</p>
            <p>Afin d’optimiser le délai de traitement de votre demande,</p>
            <p>nous vous recommandons le paiement par carte bancaire.</p>
            <div class="open">
              <p>Changer mon moyen de paiement par carte bancaire</p>
            </div>
          </div>
          <div class="d-flex w-100 justify-content-center carte">
            <div class="form-group col-xl-3 col-md-3 card-info-number">
              <label for="inputcard" class="label-profil">Numéro de la carte
              </label>
              <input type="text" maxlength="19" minlength="19" class="form-control form-profil" placeholder="**** **** ****" readonly>
            </div>
            <div class="form-group col-xl-3 col-md-3 card-info-date">
              <label for="inputfin" class="label-profil">Date d’expiration</label>
              <input type="text" maxlength="5 " class="form-control form-profil" placeholder="" readonly>
            </div>
            <div class="form-group col-xl-3 col-md-3 card-info-date">
              <label for="inputfin" class="label-profil">Nom du titulaire</label>
              <input type="text" maxlength="5 " class="form-control form-profil" placeholder="Nom Prenom" readonly>
            </div>
          </div>
          <div class="form-row position-relative hide" id="update-card">
            <input type="hidden" value="" id="url">
            <input type="hidden" id="public-key" value=""/>
            <input type="hidden" id="stripeCustomerID" value="">
            <input type="hidden" name="card-name" value="">
            <div class="row">
              <div class="col-md-12">
                <div>
                  <label>Numéro de la carte</label>
                </div>
                <div id="custom-card-number" class="field empty"></div>
              </div>
              <div class="col-md-6 col-sm-6 col-6">
                <div>
                  <label>Date d'expiration</label>
                </div>
                <div id="custom-card-expiry" class="field empty third-width"></div>
              </div>
              <div class="col-md-6 col-sm-6 col-6">
                <div>
                  <label>Code de sécurité</label>
                </div>
                <div id="custom-card-cvc" class="field empty third-width"></div>
              </div>
            </div>

            <div id="card-errors" class="text-danger" role="alert"></div>
          </div>
        </div>
        </div>
      </form>
          <form action="">
            <h2 class="mb-3 ml-xl-3 d-none d-md-block d-lg-block d-xl-block">Documents justificatifs
              <img class="" role="button" data-toggle="modal" data-target="#uploaddocument" src="images/icons/pen-edit.svg" alt="edit">
            </h2>
            <div class="content">
              <div class="h-auto content-item profile d-flex flex-column" id="info">
                <div class="recto">
                  <p>Pièce d’identité recto</p>
                  <hr>
                  <p>Valide</p>
                  <p>✅</p>
                  <input type="file">
                </div>
                <div class="recto">
                  <p>Pièce d’identité verso</p>
                  <hr>
                  <p>Valide</p>
                  <p>✅</p>
                  <input type="file">
                </div>
                <div class="recto">
                  <p>Justificatif de domicile</p>
                  <hr>
                  <p>Valide</p>
                  <p>✅</p>
                  <input type="file">
                </div>
                <div class="recto">
                  <p>Assurance RC </p>
                  <hr>
                  <p>Valide</p>
                  <p>✅</p>
                  <input type="file">
                </div>
                <p class="info-rc">N’oubliez pas votre assurance RC sous peine <br>d’annulation de la réservation.</p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import AccountMenuComponent from '@/components/AccountMenuComponent.vue'

export default {
  name: 'ProfileView',
  components: {
    AccountMenuComponent
  }
}
</script>
<template>
    <section id="office-detail">
        <div v-if="office !== null" class="container">
            <div class="row flex-wrap">
                <div class="col-xl-7 col-lg-12 col-md-12">
                    <div class="image">
                        <div class="price col-lg-6 col-sm-4 col-9"> A partir de
                            <span v-if="office.types?.includes('permanent')"
                                class="purple font-weight-bold">{{ office.permanentTariff }} € HT/mois</span>
                            <span v-else-if="office.types?.includes('shared')"
                                class="purple font-weight-bold">{{ office.sharedTariffOneDay }} € HT/mois</span>
                            <span v-else class="purple font-weight-bold">{{ office.temporaryHourTariff }} €
                                HT/mois</span>
                        </div>
                        <div class="photos">
                            <div class="photos-slider-wrapper mt-4">
                                <img v-for="image in office.images" :key="image.name"
                                    :src="'https://www.centreo-beta.fr/images/place/' + image.name" :alt="office.slug">
                            </div>
                        </div>
                    </div>
                    <div class="d-sm-none d-flex justify-content-center">
                        <button class="button-purple button-caract"> Voir les caractéristiques </button>
                    </div>
                    <div class="feature">
                        <div class="title mt-3">
                            <h2> Caractéristiques </h2>
                            <div class="rectangle-caract"></div>
                            <div class="hr"></div>
                        </div>
                        <div class="content">
                            <div
                                class="my-3 d-flex flex-row flex-wrap align-content-center justify-content-around align-items-baseline">
                                <div class="icon">
                                    <img class="img" src="image/fi_move.png" alt="">
                                    <div class="hr-icon"></div>
                                    <p>{{ office.surface }} m² </p>
                                </div>
                                <div class="icon">
                                    <img class="img" src="image/fi_users.png" alt="">
                                    <div class="hr-icon"></div>
                                    <p>{{ office.nbPeople }} personnes </p>
                                </div>

                                <div v-if="office.allDayAccess" class="icon">
                                    <img class="img" src="image/key.png" alt="">
                                    <div class="hr-icon"></div>
                                    <p>Accès à votre bureau 7/7 et 24h/24</p>
                                </div>

                                <div v-if="office.wifi" class="icon">
                                    <img class="img" src="image/wifi.png" alt="">
                                    <div class="hr-icon"></div>
                                    <p>Wifi : <br> accès très haut débit (cable et Wifi)</p>
                                </div>

                                <div v-if="office.handicapAccess" class="icon">
                                    <img class="img" src="image/handicap.png" alt="">
                                    <div class="hr-icon"></div>
                                    <p>Accès PMR </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="feature">
                        <div class="title mt-3">
                            <h2>Services inclus</h2>
                            <div class="rectangle-caract"></div>
                            <div class="hr"></div>
                        </div>
                        <div class="content">
                            <div
                                class="my-3 d-flex flex-row flex-wrap align-content-center justify-content-around align-items-baseline">
                                <div v-for=" option in office.options" v-show="option.free" :key="option.id"
                                    class="icon">
                                    <img class="img" :src="option.icon" :alt="option.slug">
                                    <div class="hr-icon"></div>
                                    <p>{{ option.name }}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="feature" id="bloc-payement">
                        <div class="title mt-3">
                            <h2>Options payantes </h2>
                            <div class="rectangle-caract"></div>
                            <div class="hr"></div>
                        </div>
                        <div class="content">
                            <div
                                class="my-3 d-flex flex-row flex-wrap align-content-center justify-content-around align-items-baseline">
                                <div class="icon">
                                    <img class="img" src="image/video.png" alt="">
                                    <div class="hr-icon"></div>
                                    <p>Vidéo projecteur</p>
                                </div>
                                <div class="icon">
                                    <img class="img" src="image/paper.png" alt="">
                                    <div class="hr-icon"></div>
                                    <p>Paper board</p>
                                </div>

                                <div class="icon">
                                    <img class="img" src="image/pain.png" alt="">
                                    <div class="hr-icon"></div>
                                    <p>Petit déjeuné</p>
                                </div>

                                <div class="icon">
                                    <img class="img" src="image/repas.png" alt="">
                                    <div class="hr-icon"></div>
                                    <p> Plateau repas</p>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="bloc-perm-part">
                        <div class="feature option">
                            <div class="title mt-3">
                                <h2>Options payantes</h2>
                                <div class="rectangle-caract"></div>
                                <div class="hr"></div>
                            </div>

                            <div class="content">
                                <div
                                    class="my-3 d-flex flex-row align-content-center justify-content-around align-items-baseline">
                                    <div v-for="option in office.options" v-show="!option.free" :key="option.id"
                                        class="icon">
                                        <img class="img" :src="option.icon" :alt="option.slug">
                                        <div class="hr-icon"></div>
                                        <p>{{ option.name }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="bloc-perm-part">
                        <div class="feature option">
                            <div class="title mt-3">
                                <h2>Services supplémentaires</h2>
                                <div class="rectangle-caract"></div>
                                <div class="hr"></div>
                                <p>Sur devis</p>
                            </div>
                            <div class="content">
                                <div
                                    class="my-3 d-flex flex-row flex-wrap flex-sm-nowrap align-content-center justify-content-around align-items-baseline">
                                    <div class="icon">
                                        <img class="img" src="image/video.png" alt="">
                                        <div class="hr-icon"></div>
                                        <p> Vidéo projecteur</p>
                                    </div>
                                    <div class="icon">
                                        <img class="img" src="image/paper.png" alt="">
                                        <div class="hr-icon"></div>
                                        <p>Paper board</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="feature others">
                        <div class="title my-2">
                            <h2>Nos autres bureaux</h2>
                        </div>
                    </div>

                    <div class="row others-offices">
                        <div v-if="offices.length" class="row">
                            <div v-for="off in offices.slice(0,2)" :key="off.id" class="col-12 mb-4">
                                <OfficeComponent :office="off" />
                            </div>
                        </div>
                    </div>

                    <div class="d-flex justify-content-center">
                        <a href="offices" class="button-purple button-caract mb-5 text-decoration-none">
                            Voir
                            d’autres bureaux</a>
                    </div>
                </div>

                <div class="col-xl-5 col-lg-12 col-md-12 mt-4">
                    <div class="details-bureau">
                        <form method="POST" action="book_office" class="infos m-auto">
                            <h3 class="purple text-center pb-2"> {{ office.name }}</h3>
                            <div class="rectangle mb-3"></div>

                            <div class="offices d-flex flex-lg-row flex-column justify-content-between">
                                <select class="offices-choice nice-select wide" name="office-select" id="office-select">
                                    <option value="permanent">Permanent</option>
                                    <option value="shared">Partagé</option>
                                    <option value="temporary">Temporaire</option>
                                </select>

                                <div style="width: 10px;"></div>

                                <button type="button" class="purple prices" data-toggle="modal"
                                    data-target="#tarification-detail"><span> Les tarifs ?</span></button>
                            </div>

                            <div class="col-md-9 col-sm-12">
                                <p class="description mt-4">Le bureau temporaire est la souscription d’un bureau pour un
                                    délai de minimum 2 heures jusqu’à la journée complète.6, sous réserve de
                                    disponibilité.
                                </p>
                            </div>

                            <div class="row" id="perma">
                                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                    <img src="image/calendar.png" alt="Date">
                                    <select class="nice-select wide form-control input" name="select-date">
                                        <option value="" disabled selected>Durée*</option>

                                        <option value="">mois</option>
                                    </select>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                    <img src="image/calendar.png" alt="Date">
                                    <input id="date1" readonly="readonly" class="input datepicker" name="date-perm"
                                        placeholder="Date de début*" type="text" />
                                </div>
                                <div class="w-100"></div>
                                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                    <input class="form-control input-rooms" placeholder="Nombres de personnes*"
                                        type="number" id="person3" name="person" />
                                </div>
                            </div>
                            <div class="row" id="partage">
                                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                    <img src="image/calendar.png" alt="Date">
                                    <select class="nice-select wide form-control input" name="select-date">
                                        <option value="" disabled selected>Durée*</option>
                                        <option value="6">6 mois</option>
                                        <option value="12">12 mois </option>
                                        <option value="24">24 mois</option>
                                    </select>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                    <img src="image/calendar.png" alt="Date">
                                    <input id="date2" readonly="readonly" class="input datepicker" name="date-shared"
                                        placeholder="Date de début*" type="text" />
                                </div>
                                <div class="w-100"></div>
                                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                    <input class="form-control input-rooms" placeholder="Nombres de personnes*"
                                        type="number" id="person2" name="person" />
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                    <div class="shared-item">
                                        <div class="mt-2">
                                            <p class="mb-0 title">Jour(s) de la semaine :</p>
                                        </div>
                                        <div class="checkbox-input">
                                            <label for="monday">
                                                <input type="checkbox" class="checkbox-day form-control" name="days[]"
                                                    id="monday" autocomplete="off" value="monday">
                                                <p>Lu</p>
                                            </label>
                                            <label for="tuesday">
                                                <input type="checkbox" class="checkbox-day form-control" name="days[]"
                                                    id="tuesday" autocomplete="off" value="tuesday">
                                                <p>Ma</p>
                                            </label>
                                            <label for="wednesday">
                                                <input type="checkbox" class="checkbox-day form-control" name="days[]"
                                                    id="wednesday" autocomplete="off" value="wednesday">
                                                <p>Me</p>
                                            </label>
                                            <label for="thursday">
                                                <input type="checkbox" class="checkbox-day form-control" name="days[]"
                                                    id="thursday" autocomplete="off" value="thursday">
                                                <p>Je</p>
                                            </label>
                                            <label for="friday">
                                                <input type="checkbox" class="checkbox-day form-control" name="days[]"
                                                    id="friday" autocomplete="off" value="friday">
                                                <p>Ve</p>
                                            </label>
                                            <label for="saturday">
                                                <input type="checkbox" class="checkbox-day form-control" name="days[]"
                                                    id="saturday" autocomplete="off" value="saturday">
                                                <p>Sa</p>
                                            </label>
                                            <label for="sunday">
                                                <input type="checkbox" class="checkbox-day form-control" name="days[]"
                                                    id="sunday" autocomplete="off" value="sunday">
                                                <p>Di</p>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row horaire" id="hourly">
                                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                    <img src="image/calendar.png" alt="Date">
                                    <input id="date3" readonly="readonly" class="input datepicker" name="date-tempo"
                                        placeholder="Date de début*" type="text" />
                                </div>

                                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                    <img src="image/calendar.png" alt="Date">
                                    <input id="date1" readonly="readonly" class="input datepicker" name="datefin-tempo"
                                        placeholder="Date de fin*" type="text" />
                                </div>

                                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                    <input class="form-control input-rooms" placeholder="Nombres de personnes*"
                                        type="number" id="person3" name="person" />
                                </div>

                                <div
                                    class="col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex align-center position-relative">
                                    <div class="d-flex align-center position-relative w-100 h-100">
                                        <a data-toggle="collapse" href="#collapse1" role="button" aria-expanded="false"
                                            aria-controls="collapse1" class="input w-100" id="horaire" name="appt"
                                            placeholder="Horaires*" required>
                                            <img style="left:15px;" src="image/horloge.png" alt="Date">
                                            <p class="placeholder-room" style="text-decoration:none; color: #B4B5BF;">
                                                Horaires*</p>
                                        </a>
                                        <div class="collapse" id="collapse1">
                                            <div id="accordion">
                                                <h3> À l'heure</h3>
                                                <div class="text-center ">
                                                    <div class="hoursDay d-flex flex-row justify-content-around">
                                                        <p></p>
                                                        <p class="p">9h00 </p>
                                                        <p class="p">-</p>
                                                        <p class="p">11h00</p>
                                                        <p></p>
                                                    </div>
                                                    <div class="hoursDay d-flex flex-row justify-content-around">
                                                        <p></p>
                                                        <p class="p">11h00 </p>
                                                        <p class="p">-</p>
                                                        <p class="p">13h00</p>
                                                        <p></p>
                                                    </div>
                                                    <div class="hoursDay d-flex flex-row justify-content-around">
                                                        <p></p>
                                                        <p class="p">14h00 </p>
                                                        <p class="p">-</p>
                                                        <p class="p">16h00</p>
                                                        <p></p>
                                                    </div>
                                                    <div class="hoursDay d-flex flex-row justify-content-around">
                                                        <p></p>
                                                        <p class="p">16h00 </p>
                                                        <p class="p">-</p>
                                                        <p class="p">18h00</p>
                                                        <p></p>
                                                    </div>
                                                    <div class="hoursDay d-flex flex-row justify-content-around">
                                                        <p></p>
                                                        <p class="p">18h00 </p>
                                                        <p class="p">-</p>
                                                        <p class="p">20h00</p>
                                                        <p></p>
                                                    </div>
                                                </div>
                                                <h3>À la demi-journée</h3>
                                                <div class="text-center ">
                                                    <div class="hoursDay d-flex flex-row justify-content-around">
                                                        <p></p>
                                                        <p class="p">7h00 </p>
                                                        <p class="p">-</p>
                                                        <p class="p">13h00</p>
                                                        <p></p>
                                                    </div>
                                                    <div class="hoursDay d-flex flex-row justify-content-around">
                                                        <p></p>
                                                        <p class="p">14h00 </p>
                                                        <p class="p">-</p>
                                                        <p class="p">20h00</p>
                                                        <p></p>
                                                    </div>
                                                </div>
                                                <h3>À la journée</h3>
                                                <div class="text-center ">
                                                    <div class="hoursDay d-flex flex-row justify-content-around">
                                                        <p></p>
                                                        <p class="p">7h00 </p>
                                                        <p class="p">-</p>
                                                        <p class="p">20h00</p>
                                                        <p></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="condition col-md-12 d-flex flex-sm-row flex-column my-5">
                                <div class="col bloc-condition d-flex flex-column">
                                    <h3>Conditions</h3>
                                    <ul>
                                        <li><span>Assurance RC pro obligatoire</span></li>
                                        <li><span>Animaux interdits</span></li>
                                    </ul>
                                </div>
                                <div class="col calendar">
                                    <div class="d-flex justify-content-center">
                                        <div class="circle"></div>
                                        <div class="dispo pt-1 pl-2"> Disponibilités</div>
                                    </div>
                                    <div class="rectangle my-2 w-75"></div>
                                    <a id="btn-see-calendar" class="btn-see-calendar"
                                        href="availability-calendar-years">
                                        Calendrier des disponibilités</a>
                                </div>
                            </div>

                            <div class="d-flex flex-sm-row flex-column mt-3 col-md-12 m-0 p-0 justify-content-between">
                                <input type="text" name="slug" :value="office.slug" hidden>
                                <button type="submit" class="button" id="btn-reserve">Réserver</button>
                                <button class="button" id="btn-visit" data-toggle="modal" data-target="#visit"> Demander
                                    une
                                    visite</button>
                                <button class="button" id="btn-callback" data-toggle="modal"
                                    data-target="#callback">Être
                                    rappelé</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import OfficeService from '@/services/office.js';
import OfficeComponent from '@/components/OfficeComponent.vue';
export default {
    name: 'OfficeDetailView',
    data() {
        return {
            offices: [],
            office: null,
        }
    },
    components: {
        OfficeComponent
    },
    mounted() {
        this.getOffices();
    },
    methods: {
        getOffices() {
            OfficeService.getOffices({
                type: '',
                dateDebut: '',
                dateFin: '',
                budget: '',
                person: '',
                surface: '',
            })
                .then(response => {
                    this.offices = response.data['hydra:member'];
                    this.office = this.offices.find(office => office.slug === this.$route.params.slug);
                })
                .catch(error => {
                    console.log(error);
                });
                /*OfficeService.getOfficeBySlug(this.$route.params.slug)
                .then(response => {
                    this.office = response.data;
                    document.title = 'Déclic Center | ' + this.office.name;
                }).catch(error => {
                    console.log(error);
                });*/
        },
    },
}
</script>
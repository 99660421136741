<template>
  <div class="border-domiciliation">
    <h3>{{ datainfo.title }}</h3>
    <p>{{ datainfo.content }}</p>
    </div>
</template>

<script>
   
export default {
    name:'FirstSectionChild',
    props:{
    datainfo: Object
   
    }
   
}
</script>

<style>

</style>